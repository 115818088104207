import React from "react";
import CtOrganizationDto from "../../model/codetable/CtOrganizationDto";
import { imageExtention } from "../../infrastructure/Utils";

interface OrganizationLogoProps {
  organization: CtOrganizationDto;
}

export default function DisplayOrganizationLogo(props: OrganizationLogoProps) {
  const { organization } = props;
  return (
    <div style={{ textAlign: "center" }}>
      <a href={props.organization.websiteUrl} target="_blank" rel="noopener noreferrer">
        <img src={`data:image/${imageExtention(organization.logo!)};base64,${organization.logo!}`} alt={props.organization.name} />
      </a>
    </div>
  );
}
