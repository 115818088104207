import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useRef, useContext } from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import PageDropdownTemplate from "../../infrastructure/system/hooks/PageDropdownTemplate";
import { PaginatorTemplateOptions } from "primereact/paginator";
import { axiosConfig, CALENDAR_DATE_FORMAT, DATE_FORMAT, DATE_FORMAT2, validateStringEmpty } from "../../infrastructure/Utils";
import { AppContext } from "../../Store";
import Endpoint from "../../infrastructure/Endpoint";
import axios from "axios";
import MessageType from "../../infrastructure/MessageType";
import StatisticsRankingReadDto from "../../model/statistics/StatisticsRankingReadDto";
import moment from "moment";
import ExportData from "../app/export/ExportData";

export default function StatisticalData() {
  const { authData, showMessage, setShowBlockUI } = useContext(AppContext);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [tableRows, setTableRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [openRankingDatatable, setOpenRankingDatatable] = useState<boolean>(false);
  const dataTableRef: any = useRef();
  const [rankList, setRankList] = useState<Array<StatisticsRankingReadDto>>();
  const { rowsPerPageDropdownTemplateMax } = PageDropdownTemplate();
  const [exportData, setExportData] = useState<Array<any>>([]);

  const validateInput = () => {
    if (validateStringEmpty(dateFrom)) {
      showMessage(MessageType.ERROR, "", Labels.LABEL_DATE_FROM_REQUIRED);
      setOpenRankingDatatable(false);
      return false;
    }

    if (validateStringEmpty(dateTo)) {
      showMessage(MessageType.ERROR, "", Labels.LABEL_DATE_TO_REQUIRED);
      setOpenRankingDatatable(false);
      return false;
    }

    if (new Date(dateFrom) > new Date(dateTo)) {
      showMessage(MessageType.ERROR, "", Labels.LABEL_DATE_FROM_AFTER_DATE_TO);
      setOpenRankingDatatable(false);
      return false;
    }

    return true;
  };

  const getRankingsForPeriod = () => {
    if (!validateInput()) return false;

    setShowBlockUI(true);
    axios
      .get(`${Endpoint.STATISTICS_RANG_LIST}`, axiosConfig(authData.token, { dateFrom: dateFrom, dateTo: dateTo }))
      .then((res: any) => {
        setRankList(res.data.data);
        setFirst(0);
        setOpenRankingDatatable(true);
        let exportList = new Array<any>();
        res.data.data?.forEach((rank: StatisticsRankingReadDto) => {
          exportList.push({
            [Labels.STATISTICS_USER_ID]: rank.id,
            [Labels.STATISTICS_USER_CHOSEN_NAME]: rank.chosenName,
            [Labels.STATISTICS_USER_BIRTH_DATE]: rank.dateOfBirth ? moment(rank.dateOfBirth).format(DATE_FORMAT) : Labels.SLASH,
            [Labels.STATISTICS_USER_HEIGHT]: rank.heightCentimeters ?? Labels.SLASH,
            [Labels.STATISTICS_USER_WIDTH]: rank.weightKilograms ?? Labels.SLASH,
            [Labels.STATISTICS_USER_KILOMETERES_TRAVELED]: rank.totalDistanceKilometers,
          });
        });
        setExportData(exportList);
      })
      .catch((error: any) => {
        const messages = error.response.data.errors
          .map((err: any) => {
            return err.message;
          })
          .join(" ");
        showMessage(MessageType.ERROR, "", messages);
        setOpenRankingDatatable(false);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const numberOfResultsContents = () => {
    if (rankList) {
      return (
        <div>
          {Labels.STATISTICS_NUMBER_OF_RESULTS} {rankList.length}
        </div>
      );
    }
  };

  const downloadButton = () => (
    <>
      <ExportData disabled={rankList && rankList.length > 0 ? false : true} data={exportData} name={Labels.LABEL_RANK_LIST} dt={dataTableRef} />
    </>
  );

  const renderDateOfBirth = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.STATISTICS_USER_BIRTH_DATE}</span>
        {rowData && rowData.dateOfBirth ? moment(rowData.dateOfBirth).format(DATE_FORMAT) : Labels.SLASH}
      </React.Fragment>
    );
  };

  const renderHeightCentimeters = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.STATISTICS_USER_HEIGHT}</span>
        {rowData && rowData.heightCentimeters ? rowData.heightCentimeters : Labels.SLASH}
      </React.Fragment>
    );
  };

  const renderWeightKilograms = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.STATISTICS_USER_HEIGHT}</span>
        {rowData && rowData.weightKilograms ? rowData.weightKilograms : Labels.SLASH}
      </React.Fragment>
    );
  };

  return (
    <div className="layout-article">
      <Panel header={Labels.MENU_STATISTICAL_DATA}>
        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
          <div className="p-grid p-align-center p-nogutter">
            <div className="p-col-2">
              {Labels.LABEL_DATE_FROM}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
            <div className="p-col-4 p-fluid margin-top-bottom-5">
              <Calendar
                autoZIndex
                value={dateFrom ? new Date(dateFrom) : undefined}
                onChange={(e: any) => {
                  setDateFrom(e?.value ? moment(e.value.toString()).format(DATE_FORMAT2) : "");
                }}
                dateFormat={CALENDAR_DATE_FORMAT}
                showIcon={true}
              />
            </div>
          </div>

          <div className="p-grid p-align-center p-nogutter">
            <div className="p-col-2">
              {Labels.LABEL_DATE_TO}
              {Labels.SPECIAL_CHAR_REQUIRED}
            </div>
            <div className="p-col-4 p-fluid margin-top-bottom-5">
              <Calendar
                autoZIndex
                value={dateTo ? new Date(dateTo) : undefined}
                onChange={(e: any) => {
                  setDateTo(e?.value ? moment(e.value.toString()).format(DATE_FORMAT2) : "");
                }}
                dateFormat={CALENDAR_DATE_FORMAT}
                showIcon={true}
              />
            </div>
          </div>
          <Button icon="pi pi-check" className="button" disabled={false} label={Labels.STATISTICS_GENERATE_DATATABLE} onClick={getRankingsForPeriod} />
        </div>
        {openRankingDatatable && (
          <div>
            <Toolbar left={numberOfResultsContents} right={downloadButton} />
            <div className="datatable-responsive">
              <DataTable
                ref={dataTableRef}
                value={rankList}
                paginator
                first={first}
                paginatorTemplate={rowsPerPageDropdownTemplateMax as PaginatorTemplateOptions}
                rows={tableRows}
                onPage={(e) => {
                  setTableRows(e.rows);
                  setFirst(e.first);
                }}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                className="p-datatable-responsive"
              >
                <Column field={"id"} header={Labels.STATISTICS_USER_ID} />
                <Column field={"chosenName"} header={Labels.STATISTICS_USER_CHOSEN_NAME} />
                <Column field={"dateOfBirth"} header={Labels.STATISTICS_USER_BIRTH_DATE} body={renderDateOfBirth} />
                <Column field={"heightCentimeters"} header={Labels.STATISTICS_USER_HEIGHT} body={renderHeightCentimeters} />
                <Column field={"weightKilograms"} header={Labels.STATISTICS_USER_WIDTH} body={renderWeightKilograms} />
                <Column field={"totalDistanceKilometers"} header={Labels.STATISTICS_USER_KILOMETERES_TRAVELED} />
              </DataTable>
            </div>
          </div>
        )}
      </Panel>
    </div>
  );
}
