import axios from "axios";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Password } from "primereact/password";
import React, { useContext, useState } from "react";
import AuthData from "../AuthData";
import Endpoint from "../infrastructure/Endpoint";
import Labels from "../infrastructure/Labels_sr_Latn_RS";
import { AppContext } from "../Store";
import { NavLink } from "react-router-dom";
import { handleAxiosCallError, handleAxiosCallSuccess } from "../infrastructure/Utils";
import MessageType from "../infrastructure/MessageType";

export default function LoginPage() {
  const { updateAuthData, showMessage } = useContext(AppContext);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberPass, setRememberPass] = useState(false);

  const postLogin = () => {
    const postData = {
      username: userName,
      password: password,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;",
      },
    };
    axios
      .post(Endpoint.AUTHENTICATE, postData, axiosConfig)
      .then((result) => {
        const authData = new AuthData(result.data.data.jwt, result.data.data.user);
        updateAuthData(JSON.stringify(authData));
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const resetPassword = () => {
    if (!userName) {
      showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_RESET_PASSWORD, Labels.MESSAGES_EMAIL_FIELD);
      return;
    }

    axios
      .post(
        `${Endpoint.RESET_PASSWORD}/${userName}`,
        {},
        {
          headers: {
            "Content-Type": "application/json;",
          },
        }
      )
      .then((response) => {
        handleAxiosCallSuccess(showMessage, response);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  return (
    <div className="p-grid p-fluid layout-login">
      <div className="p-col-12 p-lg-6">
        <div className="p-col-12 panel-registration">
          <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center div-size">
            <Panel header={Labels.TITLE_LOGIN}>
              <form onSubmit={postLogin}>
                <div className="p-col-12">
                  <div className="p-col-12 login-input">
                    <InputText
                      className="p-col-12"
                      value={userName}
                      onChange={(e: any) => {
                        setUserName(e.target.value);
                      }}
                      placeholder={Labels.PLACEHOLDER_EMAIL_REQUIRED}
                      autoComplete="username"
                    />
                  </div>
                  <div className="p-col-12 login-input">
                    <Password
                      className="p-col-12"
                      feedback={false}
                      value={password}
                      onChange={(e: any) => {
                        setPassword(e.target.value);
                      }}
                      placeholder={Labels.PLACEHOLDER_PASSWORD}
                      autoComplete="current-password"
                    />
                  </div>
                  <div className="p-col-12">
                    <Checkbox inputId="checkBoxRememberPass" onChange={() => setRememberPass(!rememberPass)} checked={rememberPass} /> &nbsp;
                    <label htmlFor="checkBoxRememberPass" className="p-checkbox-label">
                      {Labels.CHECKBOX_REMEMBER_PASSWORD}
                    </label>
                  </div>
                  <div className="p-col-12 login-button">
                    <NavLink to="/">
                      <Button label={Labels.BUTTON_LOGIN} className="p-col-12" onClick={postLogin} />
                    </NavLink>
                  </div>
                  <div className="p-col-12">
                    <a href="#." onClick={resetPassword}>
                      {Labels.FORGOTTEN_PASSWORD}
                    </a>
                  </div>
                </div>
              </form>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
}
