import axios from "axios";
import moment from "moment";
import { Card } from "primereact/card";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import Images from "../../infrastructure/Images";
import { axiosConfig, DATE_FORMAT, getImage, handleAxiosCallError } from "../../infrastructure/Utils";
import UserReadDto from "../../model/user/UserReadDto";
import { AppContext } from "../../Store";
import DisplayActivityList from "../activity/DisplayActivityList";

export default function UserProfile() {
  const { authData, showMessage } = useContext(AppContext);

  const [user, setUser] = useState<UserReadDto>();
  const { id }: any = useParams();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const requestUser = axios.get(`${Endpoint.USER}/userList/${id}`, axiosConfig(authData.token));
    axios
      .all([requestUser])
      .then(
        axios.spread((responseUser) => {
          setUser(responseUser.data.data);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  return (
    <div className="layout-profile">
      <div className="p-grid ">
        <div className="user-info-container">
          <Card>
            <div className="p-grid p-align-center p-justify-center user-info">
              <img className="p-col-12" src={getImage(false, user?.profilePicture!, Images.profile)} alt="Avatar" />
              <div className="p-col-12"> {user?.email} </div>
              {user?.chosenName && <div className="p-col-12">{user?.chosenName}</div>}
              {user?.dateOfBirth && <div className="p-col-12">{moment(user?.dateOfBirth).format(DATE_FORMAT)}</div>}
              {user?.heightCentimeters && <div className="p-col-12">{user?.heightCentimeters + "cm"}</div>}
              {user?.weightKilograms && <div className="p-col-12">{user?.weightKilograms + "kg"}</div>}
            </div>
          </Card>
        </div>
        <div className="activity-list-container">
          <DisplayActivityList userId={id} />
        </div>
      </div>
    </div>
  );
}
