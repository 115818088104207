import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState, useRef } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig, DATE_TIME_FORMAT_FULL, handleAxiosCallError } from "../../infrastructure/Utils";
import ArticleCreateDto from "../../model/article/ArticleCreateDto";
import ArticleReadDto from "../../model/article/ArticleReadDto";
import ArticleUpdateDto from "../../model/article/ArticleUpdateDto";
import { AppContext } from "../../Store";
import CrudArticle from "./CrudArticle";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Dropdown } from "primereact/dropdown";

export default function CrudArticleList() {
  const { authData, showMessage, setShowBlockUI } = useContext(AppContext);

  const [articleList, setArticleList] = useState<Array<ArticleReadDto>>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [onCarousel, setOnCarousel] = useState<boolean | null>();
  const [entityOperation, setEntityOperation] = useState<any>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [articleToChange, setArticleToChange] = useState<ArticleReadDto>();
  const [selectedArticle, setSelectedArticle] = useState<ArticleReadDto | null>();
  const [first, setFirst] = useState(0);
  const [articleAreaList, setArticleAreaList] = useState([]);
  const [articleTypeList, setArticleTypeList] = useState([]);
  const [selectedArticleArea, setSelectedArticleArea] = useState();
  const [selectedArticleType, setSelectedArticleType] = useState();
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const primaryDataIndex: number = 0;
  const contentIndex: number = 1;
  const summaryPreviewIndex: number = 2;
  const contentPreviewIndex: number = 3;
  const [tableRows, setTableRows] = useState(10);
  const dataTableRef: any = useRef();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedArticle?: number) => {
    setShowBlockUI(true);
    const requestArticleList = axios.get(Endpoint.ARTICLE_LIST, axiosConfig(authData?.token, { size: 50000, page: 0 }));
    const requestActiveArticleAreaList = axios.get(Endpoint.ACTIVE_ARTICLE_AREA_LIST, axiosConfig(authData.token));
    const requestArticleTypeList = axios.get(Endpoint.ARTICLE_TYPE_LIST, axiosConfig(authData.token));
    axios
      .all([requestArticleList, requestActiveArticleAreaList, requestArticleTypeList])
      .then(
        axios.spread((responseArticleList, responseActiveArticleAreaList, responseArticleTypeList) => {
          setArticleList(responseArticleList.data.data);
          const articleArea = responseActiveArticleAreaList.data.map((articleArea: any) => {
            return { label: articleArea.name, value: articleArea.name };
          });
          setArticleAreaList(articleArea);
          const articleType = responseArticleTypeList.data.map((articleType: any) => {
            return { label: articleType.name, value: articleType.code };
          });
          setArticleTypeList(articleType);
          setShowBlockUI(false);
          if (idSelectedArticle) {
            responseArticleList.data.data.forEach((article: any) => {
              if (idSelectedArticle === article.id) {
                setSelectedRow(article);
                setSelectedArticle(article);
              }
            });
          }
        })
      )
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
        setShowBlockUI(false);
      });
  };

  const createArticle = (article: ArticleCreateDto) => {
    setShowBlockUI(true);
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${Endpoint.ARTICLE_LIST}`, article ? article : {}, axiosConfig(authData.token))
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_CREATE_ARTICLE_SUCCESS, "");
          fetchData(response.data.data.id);
          resolve();
          setIndex(0);
        })
        .catch((error: any) => {
          setShowBlockUI(false);
          setIndex(0);
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const updateArticle = (article: ArticleUpdateDto) => {
    setShowBlockUI(true);
    return new Promise<void>((resolve, reject) => {
      axios
        .put(`${Endpoint.ARTICLE_LIST}/${article.id}`, article, axiosConfig(authData.token))
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_UPDATE_ARTICLE_SUCCESS, "");
          fetchData(response.data.data.id);
          resolve();
          setIndex(0);
        })
        .catch((error: any) => {
          setShowBlockUI(false);
          setIndex(0);
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const deleteArticle = (articleID: number) => {
    setShowBlockUI(true);
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(`${Endpoint.ARTICLE_LIST}/${articleID}`, axiosConfig(authData.token))
        .then(() => {
          setDisplayDialog(false);
          setSelectedArticle(null);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_DELETE_ARTICLE_SUCCESS, "");
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          setShowBlockUI(false);
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  function openDialog(entityOperation: String, articleToChange?: ArticleReadDto) {
    let u = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        u = articleToChange;
        break;
    }

    setEntityOperation(entityOperation);
    setArticleToChange(u);
    setDisplayDialog(true);
  }

  function closeDialog() {
    setIndex(0);
    setDisplayDialog(false);
  }

  function onCancel() {
    closeDialog();
  }

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          icon="pi pi-save"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && (index === primaryDataIndex || index === contentIndex) && (
        <Button
          label={Labels.BUTTON_UPDATE}
          icon="pi pi-inbox"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          icon="pi pi-inbox"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </React.Fragment>
  );

  const rightContentsDialog = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={() => onCancel()} />
    </React.Fragment>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  const articleAreaNameBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_ARTICLE_AREA}</span>
        {rowData.articleArea.name}
      </React.Fragment>
    );
  };
  const articleAreaTypeBodyTemplate = (rowData: ArticleReadDto) => {
    let articleTypeLabel: string = rowData?.articleType;
    articleTypeList.forEach((articleType: any) => {
      if (articleType.value === rowData?.articleType) {
        articleTypeLabel = articleType.label;
      }
    });
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_ARTICLE_TYPE}</span>
        {articleTypeLabel}
      </React.Fragment>
    );
  };
  const titleBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_ARTICLE_TITLE}</span>
        {rowData.title}
      </React.Fragment>
    );
  };
  const insertTimestampBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_ARTICLE_INSERT_TIMESTAMP}</span>
        {rowData && rowData.insertTimestamp ? moment(rowData.insertTimestamp).format(DATE_TIME_FORMAT_FULL) : Labels.SLASH}
      </React.Fragment>
    );
  };
  const onCaruselBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_ON_CARUSEL}</span>
        {rowData.onCarousel ? Labels.LABEL_YES : Labels.LABEL_NO}
      </React.Fragment>
    );
  };

  const dialogHeader = (entityOperation: String) => {
    const display = articleToChange ? articleToChange.title : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_CREATE_ARTICLE;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_DELETE_ARTICLE + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_UPDATE_ARTICLE + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_READ_ARTICLE + display;
      default:
        return "";
    }
  };

  const leftContents = () => (
    <React.Fragment>
      <Button
        className="toolbar-button"
        icon="pi pi-plus"
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        className="toolbar-button"
        disabled={!selectedArticle}
        icon="pi pi-pencil"
        onClick={() => {
          selectedArticle && openDialog(EntityOperation.UPDATE, selectedArticle);
        }}
      />
      <Button
        className="toolbar-button"
        disabled={!selectedArticle}
        icon="pi pi-info-circle"
        onClick={() => {
          selectedArticle && openDialog(EntityOperation.READ, selectedArticle);
        }}
      />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button
        disabled={!selectedArticle}
        icon="pi pi-trash"
        onClick={() => {
          selectedArticle && openDialog(EntityOperation.DELETE, selectedArticle);
        }}
      />
    </React.Fragment>
  );

  const onArticleAreaFilterChange = (e: any) => {
    dataTableRef.current.filter(e.value, "articleArea.name", "equals");
    setSelectedArticleArea(e.value);
  };

  const renderArticleAreaFilter = () => {
    return <Dropdown value={selectedArticleArea} options={articleAreaList} onChange={onArticleAreaFilterChange} showClear className="p-column-filter" />;
  };

  const articleAreaFilter = renderArticleAreaFilter();

  const onArticleTypeFilterChange = (e: any) => {
    dataTableRef.current.filter(e.value, "articleType", "equals");
    setSelectedArticleType(e.value);
  };

  const renderArticleTypeFilter = () => {
    return <Dropdown value={selectedArticleType} options={articleTypeList} onChange={onArticleTypeFilterChange} showClear className="p-column-filter" />;
  };

  const articleTypeFilter = renderArticleTypeFilter();

  const renderOnCarouselFilter = () => {
    return (
      <TriStateCheckbox
        value={onCarousel}
        onChange={(e: any) => {
          dataTableRef.current.filter(e.value, "onCarousel", "equals");
          setOnCarousel(e.value);
        }}
      />
    );
  };
  const onCarouselFilter = renderOnCarouselFilter();

  return (
    <div className="layout-article">
      <Panel header={Labels.MENU_ARTICLE_LIST}>
        <Toolbar left={leftContents} right={rightContents} />
        <div className="datatable-responsive">
          <DataTable
            ref={dataTableRef}
            value={articleList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              setTableRows(e.rows);
              setFirst(e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedArticle(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            className="p-datatable-responsive"
          >
            <Column field={"articleArea.name"} header={Labels.LABEL_ARTICLE_AREA} filter filterElement={articleAreaFilter} body={articleAreaNameBodyTemplate} />
            <Column field={"articleType"} header={Labels.LABEL_ARTICLE_TYPE} filter filterElement={articleTypeFilter} body={articleAreaTypeBodyTemplate} />
            <Column field={"title"} className="column-left" header={Labels.LABEL_ARTICLE_TITLE} filter filterMatchMode="contains" body={titleBodyTemplate} />
            <Column field={"insertTimestamp"} className="column-width-230" header={Labels.LABEL_ARTICLE_INSERT_TIMESTAMP} sortable body={insertTimestampBodyTemplate} />
            <Column field={"onCarousel"} className="column-width-150" header={Labels.COLUMN_HEADER_ON_CARUSEL} filter filterElement={onCarouselFilter} body={onCaruselBodyTemplate} />
          </DataTable>
        </div>
        <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
          <CrudArticle
            onCreateArticle={createArticle}
            onUpdateArticle={updateArticle}
            onDeleteArticle={deleteArticle}
            articleOperation={entityOperation}
            article={articleToChange}
            onCancel={onCancel}
            dialogRef={dialogRef}
            setIndex={setIndex}
            index={index}
          />
        </Dialog>
      </Panel>
    </div>
  );
}
