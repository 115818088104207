import { Messages } from "primereact/messages";
import React, { useState, useImperativeHandle, useContext } from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, CALENDAR_DATE_FORMAT, DATE_FORMAT2, getMonthName, isFormDisabled } from "../../infrastructure/Utils";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import { TabPanel, TabView } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import ActivityRangeReadDto from "../../model/activity-range/ActivityRangeReadDto";
import { useEffectOnce } from "react-use";
import axios from "axios";
import Endpoint from "../../infrastructure/Endpoint";
import { AppContext } from "../../Store";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";

interface CrudActivityRangeProp {
  activityRangeOperation: string;
  activityRange?: ActivityRangeReadDto;
  onCancel: Function;
  onCreateActivityRange: Function;
  onDeleteActivityRange: Function;
  onUpdateActivityRange: Function;
  dialogRef?: any;
}

export default function CrudActivityRange(prop: CrudActivityRangeProp) {
  const { activityRangeOperation, activityRange, onCreateActivityRange, dialogRef, onDeleteActivityRange, onUpdateActivityRange } = prop;

  const { authData, setShowBlockUI } = useContext(AppContext);
  const [activityRangeChange, setActivityRangeChange] = useState<any>(activityRange);
  const isDisabled = isFormDisabled(activityRangeOperation);
  const [chartData, setChartData] = useState<any>({});
  const [doughnutChartData, setDoughnutChartData] = useState<any>({});
  let [messages, setMessages] = useState<any>("");

  const fetchActivityRangeDetailList = () => {
    if (activityRangeChange?.id) {
      const requestActivityRangeDetailList = axios.get(`${Endpoint.ACTIVITY_RANGE}/${activityRangeChange?.id}/activityRangeDetail`, axiosConfig(authData.token));
      axios
        .all([requestActivityRangeDetailList])
        .then(
          axios.spread((res) => {
            setChartData({
              labels: res.data.data.map((data: any) => {
                return getMonthName(data.month) + " " + data.year;
              }),
              datasets: [
                {
                  label: Labels.LABEL_REALIZE_DISTANCE,
                  backgroundColor: "#42A5F5",
                  data: res.data.data.map((data: any) => {
                    return data.totalDistanceKilometers;
                  }),
                },
              ],
            });
            if (activityRangeChange.goalKilometers) {
              let totalDistanceKilometersRealize = res.data.data
                .map((data: any) => {
                  return data.totalDistanceKilometers;
                })
                .reduce((a: number, b: number) => a + b, 0);
              setDoughnutChartData({
                labels: [Labels.LABEL_REALIZE, Labels.LABEL_NOT_REALIZE],
                datasets: [
                  {
                    data: [
                      Number.parseFloat(totalDistanceKilometersRealize).toFixed(3),
                      activityRangeChange.goalKilometers > totalDistanceKilometersRealize
                        ? Number.parseFloat((activityRangeChange.goalKilometers - totalDistanceKilometersRealize).toString()).toFixed(3)
                        : 0,
                    ],
                    backgroundColor: ["#36A2EB", "#FF6384"],
                    hoverBackgroundColor: ["#36A2EB", "#FF6384"],
                  },
                ],
              });
            }
          })
        )
        .catch((error) => {
          showError(Labels.TITLE_MESSAGES_ERROR, error.response.data.message);
        });
    }
  };

  useEffectOnce(() => {
    fetchActivityRangeDetailList();
  });

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      onCreateActivityRange(activityRangeChange)
        .then(() => {})
        .catch((error: any) => {
          showError("", error);
        });
    },
    onUpdate: () => {
      onUpdateActivityRange(activityRangeChange)
        .then(() => {})
        .catch((error: any) => {
          showError(error, "");
        });
    },
    onDelete: () => {
      onDeleteActivityRange(activityRangeChange.id)
        .then(() => {})
        .catch((error: any) => {
          showError("", error);
        });
    },
  }));

  const showError = (summary: string, detail: string) => {
    messages.replace({
      severity: "error",
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  var basicOptions = {
    maintainAspectRatio: false,
    animation: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };

  const lightOptions = {
    animation: false,
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  };

  const generateDateForChart = () => {
    setShowBlockUI(true);
    axios
      .post(`${Endpoint.ACTIVITY_RANGE}/${activityRange?.id}/activityRangeDetail`, { activityRangeID: activityRange?.id }, axiosConfig(authData.token))
      .then((res: any) => {
        showSucces("", Labels.TITLE_MESSAGES_GENERATE_ACTIVITY_RANGE_DETAILS_SUCCESS);
        fetchActivityRangeDetailList();
        setShowBlockUI(false);
      })
      .catch((error) => {
        setShowBlockUI(false);
        showError(Labels.TITLE_MESSAGES_ERROR, error.response.data.message);
      });
  };

  const showSucces = (summary: string, detail: string) => {
    messages.replace({
      severity: "success",
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  return (
    <div className=" layout-message layout-common">
      <div className="p-col-12 padding-0">
        <Messages
          ref={(el: any) => {
            setMessages(el);
          }}
        ></Messages>
      </div>
      <TabView>
        <TabPanel header={Labels.LABEL_ACTIVITY_RANGE_DATA}>
          <div className="scrollable-content">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-4">
                  <div>
                    {Labels.LABEL_DATE_FROM}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Calendar
                    autoZIndex
                    disabled={isDisabled}
                    value={activityRangeChange?.dateFrom ? new Date(activityRangeChange.dateFrom) : undefined}
                    onChange={(e: any) => {
                      setActivityRangeChange({
                        ...activityRangeChange!,
                        dateFrom: moment(e.value.toString()).format(DATE_FORMAT2),
                      });
                    }}
                    hourFormat="24"
                    dateFormat={CALENDAR_DATE_FORMAT}
                    showIcon={true}
                  />
                </div>
                <div className="p-col-4">
                  <div>
                    {Labels.LABEL_DATE_TO}
                    {Labels.SPECIAL_CHAR_REQUIRED}
                  </div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Calendar
                    autoZIndex
                    disabled={isDisabled}
                    value={activityRangeChange?.dateTo ? new Date(activityRangeChange.dateTo) : undefined}
                    onChange={(e: any) => {
                      setActivityRangeChange({
                        ...activityRangeChange!,
                        dateTo: moment(e.value.toString()).format(DATE_FORMAT2),
                      });
                    }}
                    dateFormat={CALENDAR_DATE_FORMAT}
                    showIcon={true}
                    hourFormat="24"
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_NAME}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputText
                    disabled={isDisabled}
                    value={activityRangeChange?.name ?? ""}
                    onChange={(e: any) => {
                      setActivityRangeChange({
                        ...activityRangeChange!,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_GOAL}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputNumber
                    disabled={isDisabled}
                    value={activityRangeChange?.goalKilometers ?? null}
                    onValueChange={(e: any) => {
                      setActivityRangeChange({
                        ...activityRangeChange!,
                        goalKilometers: e.target.value,
                      });
                    }}
                    showButtons
                    buttonLayout="horizontal"
                    decrementButtonClassName="p-button-danger"
                    incrementButtonClassName="p-button-success"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    min={0}
                    inputClassName="p-text-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel disabled={activityRangeOperation === EntityOperation.CREATE} header={Labels.LABEL_CHART}>
          <div className="scrollable-content">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                {activityRangeChange && (
                  <div className="p-col-8">
                    <div className="card">
                      <Chart type="bar" data={chartData} options={basicOptions} />
                    </div>
                  </div>
                )}
                {activityRangeChange && doughnutChartData.datasets && (
                  <div className="p-col-4">
                    <div className="card ml-2">
                      {activityRangeChange.name && doughnutChartData && <h6>{activityRangeChange.name}</h6>}
                      <Chart type="doughnut" data={doughnutChartData} options={lightOptions} />
                    </div>
                  </div>
                )}
              </div>
              <Button label={chartData?.labels?.length > 0 ? Labels.LABEL_UPDATE_DATA : Labels.LABEL_GENERATE_DATA} disabled={isDisabled} icon="pi pi-chart-bar" onClick={generateDateForChart} />
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
