import { now } from "moment";
import React, { useContext, useEffect, useState } from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import CommentReadDto from "../../model/comment/CommentReadDto";
import EventReadDto from "../../model/event/EventReadDto";
import { AppContext } from "../../Store";
import { dateTimeBefore, getImage } from "../../infrastructure/Utils";
import ActivityReadDto from "../../model/activity/ActivityReadDto";
import Images from "../../infrastructure/Images";

interface commentProps {
  event?: EventReadDto;
  activity?: ActivityReadDto;
  comment: CommentReadDto;
  onDeleteComment: Function;
  onEditComment: Function;
  horizontalLine: boolean;
}

export default function Comment(props: commentProps) {
  const { event, comment, onDeleteComment, horizontalLine, onEditComment, activity } = props;
  const { authData } = useContext(AppContext);
  const [deleteComment, setDeleteComment] = useState<any>({});
  const [dateNow, setDateNow] = useState<any>();

  useEffect(() => {
    setDateNow(new Date(now()));
  }, []);

  return (
    <div className="layout-comment">
      <div className="p-grid p-align-center">
        <div className="p-col-1 profile-picture">
          <img src={getImage(false, comment.user.profilePicture, Images.profile)} alt="" width="100%" />
        </div>
        <div className="p-col-8">
          <div className="p-grid p-dir-col ">
            <div> {`${comment.user.chosenName}`} </div>
            <div>{comment.text}</div>
          </div>
        </div>
        <div
          onMouseLeave={() => setDeleteComment({ ...deleteComment, [comment.id]: false })}
          onMouseOver={() => setDeleteComment({ ...deleteComment, [comment.id]: true })}
          className="p-col-3 p-grid p-align-center p-justify-end"
        >
          <div className="p-col-4 padding-0">
            {deleteComment[comment.id] && (authData.currentUser.id === comment.user.id || (event ? event.insertUser.id === authData.currentUser.id : activity?.user.id === authData.currentUser.id)) && (
              <div className="p-col-2 edit-delete-comment" onClick={() => onDeleteComment(comment.id)}>
                {Labels.LABEL_DELETE}
              </div>
            )}
            {deleteComment[comment.id] && authData.currentUser.id === comment.user.id && (
              <div className="p-col-2 edit-delete-comment" onClick={() => onEditComment(comment.id, comment.text)}>
                {Labels.LABEL_EDIT}
              </div>
            )}
          </div>
          <div className="font-size-10 p-col-8">{dateTimeBefore(comment.insertTimestamp, dateNow)}</div>
        </div>
      </div>
      {horizontalLine && <hr className="margin-top-0" />}
    </div>
  );
}
