const Labels = {
  NEW_LINE: "\n",
  TAB: "\t",
  SPACE: " ",
  DASH: " - ",
  SLASH: "/",
  // Special strings
  SPECIAL_CHAR_REQUIRED: " *",

  // Application
  APPLICATION_NAME: "Otvori plavi krug",

  // Common
  PRIVACY: "Privatnost",
  TERMS_OF_SERVICE: "Uslovi korišćenja aplikacije",
  PRIVACY_POLICY: "Politika privatnosti",
  FOLLOW_US: "Pratite nas",
  COPYRIGHT: "Developed by   ",
  HELIANT: " heliant",
  INSTAGRAM: "Instagram",
  FACEBOOK: "Facebook",
  TWITTER: "Twitter",
  MEMBERSHIP: "Članstvo",
  REGISTRATION: "Registracija",
  LOGIN: "Prijava",
  FORGOTTEN_PASSWORD: "Zaboravljena lozinka?",
  CONTACT: "Kontakt",
  CONTACT_EMAIL: "info@otvoriplavikrug.rs",

  // Title labels
  TITLE_USER_DETAILS: "Podaci o korisniku",
  TITLE_REGISTRATION_USER: "Registracija korisnika",
  TITLE_LOGIN: "Unesite Vaše pristupne parametre",
  TITLE_WELCOME: "Dobrodošli",
  TITLE_EVENT_CREATE: "Nova aktivnost",
  TITLE_EVENT_UPDATE: "Aktivnost",
  TITLE_FINISHED_EVENTS: "Završene aktivnosti",
  TITLE_FUTURE_EVENTS: "Predstojeće aktivnosti",
  TITLE_USER: "Korisnik",
  TITLE_SPONSORS: "Prijatelji projekta",
  TITLE_STATISTICS: "Statistika",
  TITLE_PROGRESS: "Napredak",
  TITLE_ACTIVITIES: "Aktivnosti",
  TITLE_ACTION_TIME: "VREME JE ZA AKCIJU!",

  // Menu labels
  MENU_DASHBOARD: "Novosti",
  MENU_EXPLORE: "Istraži",
  MENU_PROGRESS: "Napredak",
  MENU_EVENT_LIST: "Šetnje",
  MENU_ARTICLE_LIST: "Članci",
  MENU_FAQ_LIST: "Česta pitanja",
  MENU_RANK_LIST: "Rang lista",
  MENU_MESSAGE_LIST: "Poruke",
  MENU_CHART_LIST: "Grafici",
  MENU_STATISTICAL_DATA: "Statistički podaci",
  MENU_TEST: "Test",

  // Button labels
  BUTTON_CREATE: "Kreiraj",
  BUTTON_UPDATE: "Izmeni",
  BUTTON_DETAILS: "Detalji",
  BUTTON_REGISTRACIJA_EMAIL: "Registracija putem e-mail adrese",
  BUTTON_ALREADY_MEMBER: "Već ste član? Ulogujte se",
  BUTTON_LOGIN: "Prijava",
  BUTTON_LOGOUT: "Odjava",
  BUTTON_SAVE: "Sačuvaj",
  BUTTON_SAVE_AND_NOTIFY: "Sačuvaj i pošalji obaveštenje",
  BUTTON_CANCEL: "Odustani",
  BUTTON_DELETE: "Obriši",
  BUTTON_REGISTRATION: "Prihvatam i registracija",
  BUTTON_RESEND: "Pošalji opet email",
  BUTTON_USE_POINTS: "Iskoristi poene",
  BUTTON_PREVIEW: "Pregled",
  BUTTON_UPLOAD_IMAGE: "Učitaj sliku",
  BUTTON_BAN_USER: "Banuj",
  BUTTON_ADD: "Dodaj",
  BUTTON_SEND_MESSAGE: "Pošalji poruku",

  //Worning labels
  WORNING_PASSWORD: "Lozinka mora sadržati najmanje 8 alfa-numeričkih karaktera",

  // Placeholder labels
  PLACEHOLDER_FIRST_NAME_REQUIRED: "Ime *",
  PLACEHOLDER_LAST_NAME_REQUIRED: "Prezime *",
  PLACEHOLDER_CHOSEN_NAME_REQUIRED: "Izabrano ime *",
  PLACEHOLDER_USERNAME: "Korisničko ime",
  PLACEHOLDER_PASSWORD: "Lozinka",
  PLACEHOLDER_EMAIL_REQUIRED: "Email (korisničko ime) *",
  PLACEHOLDER_PASSWORD_REQUIRED: "Lozinka *",
  PLACEHOLDER_PASSWORD_CONFIRM_REQUIRED: "Potvrda lozinke *",
  PLACEHOLDER_BUTTON_LOAD_MORE: "Učitaj još",
  PLACEHOLDER_POST_COMMENT: "Dodaj komentar",
  PLACEHOLDER_CHOOSE_UPLOAD: "Odaberite fotografiju",
  PLACEHOLDER_CHOOSE_STATUS: "Izaberite status",
  PLACEHOLDER_GENDER: "Izaberite pol",
  PLACEHOLDER_RECIPIENT: "Izaberite primaoce",
  PLACEHOLDER_COUNT_SELECTED_RECIPIENT: "Izabrano je {0} korisnika",

  //MULTISELECT_PLACEHOLDERS
  PLACEHOLDER_MUNICIPALITY: "Izabrano je {0} opštine",

  // CheckBox labels
  CHECKBOX_REMEMBER_PASSWORD: "zapamti lozinku",

  // Colors
  COLOR_CRIMSON: "Crimson",
  COLOR_DODGER_BLUE: "DodgerBlue",

  // Labels
  LABEL_ILI: "ili",
  LABEL_KM: "km",
  LABEL_POSLEDNJA_AKTIVNOST: "Poslednja završena aktivnost",

  LABEL_MUNICIPALITY: "Opština",
  LABEL_EVENT_TYPE: "Tip događaja",
  LABEL_EVENT_STATUS: "Status događaja",
  LABEL_EVENT_SPORT: "Tip događaja",
  LABEL_START_TIME: "Vreme početka",
  LABEL_START_TOLERANCE_MINUTES: "Dozvoljeno kašnjenje [min]",
  LABEL_LENGTH_KM: "Dužina [km]",
  LABEL_LENGTH_M: "Dužina [m]",
  LABEL_REWARD_POINTS: "Nagradnih poena",
  LABEL_DESCRIPTION: "Opis",
  LABEL_NOTE: "Napomena",
  LABEL_GPS_LOCATION_START: "GPS start marker",
  LABEL_GPS_LOCATION_POINTS: "GPS route markers ",
  LABEL_GPS_LOCATION_END: "GPS end marker",
  LABEL_EMAIL: "Email",
  LABEL_NOTIFICATION: "Notifikacija",
  LABEL_FIRSTNAME: "Ime",
  LABEL_LASTNAME: "Prezime",
  LABEL_CHOSEN_NAME: "Izabrano ime",
  LABEL_DATE_OF_BIRTH: "Datum rođenja",
  LABEL_PROFILE_PICTURE: "Profilna slika",
  LABEL_AVATAR1: "Avatar1",
  LABEL_AVATAR2: "Avatar2",
  LABEL_AVATAR3: "Avatar3",
  LABEL_USED_POINTS: "Potrošeni poeni:",
  LABEL_CURRENT_POINTS: "Preostali poeni:",
  LABEL_EARNED_POINTS: "Osvojeni poeni:",
  LABEL_ADMIN: "Admin",
  LABEL_NEEDED_POINTS: "Potreban broj poena:",
  LABEL_REGISTRATION_ACCEPT: "Registracijom prihvatate ",
  LABEL_READ_HERE: "Ovde možete pročitati ",
  LABEL_DELETE: "Obriši",
  LABEL_POST: "Postavi",
  LABEL_BEFORE: "pre",
  LABEL_MINUTES: "minuta",
  LABEL_HOURS: "časova",
  LABEL_VIEW_COMMENTS: "Vidi sve komentare",
  LABEL_EDIT: "Izmeni",
  LABEL_WEEKLY_ACTIVITY: "NEDELJNE AKTIVNOSTI",
  LABEL_YEAR_TO_DATE: "GODIŠNJE AKTIVNOSTI",
  LABEL_ALL_TIME: "UKUPNO",
  LABEL_RUNS: "Događaji",
  LABEL_DISTANCE: "Distanca",
  LABEL_WALKING: "Šetnja",
  LABEL_FAST_WALKING: "Brzo hodanje",
  LABEL_RUNNING: "Trčanje",
  LABEL_EVENT_MAP: "Mapa događaja",
  LABEL_CRUD_EVENT_DATA: "Osnovni podaci o događaju",
  LABEL_USER_PROFILE: "Korisnički profil",
  LABEL_GENDER: "Pol",
  LABEL_MALE: "Muški",
  LABEL_FEMALE: "Ženski",
  LABEL_SELECTED_0_MUNICIPALITY: "Izabrano je 0 opština",
  LABEL_EVENT_DIFFICULTY: "Težina događaja",
  ACTIVITY_DURATION: "Trajanje aktivnosti",
  LABEL_ARTICLE_AREA: "Oblast članka",
  LABEL_ARTICLE_TYPE: "Tip članka",
  LABEL_ARTICLE_TITLE: "Naslov",
  LABEL_QUESTION_AREA: "Tip pitanja",
  LABEL_ARTICLE_SUMMARY: "Sažetak",
  LABEL_ARTICLE_SUMMARY_IMAGE: "Fotografija sažetka",
  LABEL_ARTICLE_BODY: "Sadržaj",
  LABEL_ARTICLE_BODY_HTML: "HTML sadržaj",
  LABEL_ARTICLE_URL: "Web link",
  LABEL_ARTICLE_VIDEO: "https://www.youtube.com/watch?v=",
  LABEL_DISPLAY_ORDER: "Prikazni redosled",
  LABEL_ARTICLE_INSERT_TIMESTAMP: "Vreme kreiranja",
  LABEL_HEIGHT_CM: "Visina (cm)",
  LABEL_WEIGHT_KG: "Težina (kg)",
  LABEL_REGISTRATION_TITLE: "POTVRDA REGISTRACIJE",
  LABEL_REGISTRATION_STATUS_SUCCESS: "Registracija je uspešno izvršena. Možete ugasiti Web pretraživač i ponovo pokrenuti aplikaciju Otvori plavi krug.",
  LABEL_REGISTRATION_STATUS_FAILURE: "Neuspešna registracija.",
  LABEL_PAGE_IN_PROGRESS: "Stranica je u izradi.",
  LABEL_RANG_LIST_MONTH: "Mesečna lista",
  LABEL_RANG_LIST_YEAR: "Godišnja lista",
  LABEL_STEPS: "Koraci",
  LABEL_WEB_SITE_IMAGE: "Fotografija za veb-sajt",
  LABEL_STORY_SHATE_IMAGE: "Fotografija za priču",
  LABEL_ARTICLE_ON_CAROUSEL: "Članak ide na karusel",
  LABEL_TABLEAU_URL: "URL za Tableau",
  LABEL_MAX_PERSONS: "Najviše učesnika",
  LABEL_TITLE: "Naslov",
  LABEL_SUMMARY_IMAGE: "Fotografija sažetka",
  LABEL_YES: "Da",
  LABEL_NO: "Ne",
  LABEL_REGISTERED_USERS: "Registrovani korisnici",
  LABEL_REGISTRATION_EXPORT_PDF: "Registracije.pdf",
  LABEL_PUBLIC_TRANSPORT_LINES: "Linije prevoza",
  LABEL_REGISTRATION_CODE: "Šifra prijave: ",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "Da li ste sigurni da želite da se odjavite?",
  LABEL_EMAIL_TEXT: "Sadržaj email-a",
  LABEL_EMAIL_TITLE: "Naslov email-a",
  LABEL_NOTIFICATION_TEXT: "Sadržaj notifikacije",
  LABEL_IS_EMAIL: "Da li se šalje email",
  LABEL_IS_NOTIFICATION: "Da li se šalje notifikacija",
  LABEL_SEND_TIME: "Vreme slanja",
  LABEL_MESSAGE_DATA: "Osnovno o poruci",
  LABEL_ACTIVITY_RANGE_DATA: "Osnovno o opsegu",
  LABEL_MESSAGE_TO_USER_LIST: "Primaoci",
  SEND_MESSAGE_TO_REGISTERED_USER_LIST: "Slanje poruke prijavljenim šetačima",
  LABEL_ACTIVITY_RANGE: "Opsezi aktivnosti",
  LABEL_CHART_LIST: "Grafici",
  LABEL_DATE_FROM: "Datum od",
  LABEL_DATE_TO: "Datum do",
  LABEL_NAME: "Naziv",
  LABEL_GOAL: "Cilj [km]",
  LABEL_CHART: "Grafik",
  LABEL_JANUARY: "januar",
  LABEL_FEBRUARY: "februar",
  LABEL_MARCH: "mart",
  LABEL_APRIL: "april",
  LABEL_MAY: "maj",
  LABEL_JUNE: "jun",
  LABEL_JULY: "jul",
  LABEL_AUGUST: "avgust",
  LABEL_SEPTEMBER: "septembar",
  LABEL_OCTOBER: "oktobar",
  LABEL_NOVEMBER: "novembar",
  LABEL_DECEMBER: "decembar",
  LABEL_REALIZE: "Ostvareno",
  LABEL_NOT_REALIZE: "Do cilja",
  LABEL_GENERATE_DATA: "Generiši podatke",
  LABEL_UPDATE_DATA: "Ažuriraj podatke",
  LABEL_REALIZE_DISTANCE: "Pređеni kilometri",
  LABEL_DATATABLE_ROWS_PER_PAGE_DROPWDOWN_ALL: "Sve",
  LABEL_DATE_FROM_REQUIRED: "Datum od je obavezno polje",
  LABEL_DATE_TO_REQUIRED: "Datum do je obavezno polje",
  LABEL_DATE_FROM_AFTER_DATE_TO: "Datum od ne sme biti veći od datum do!",
  LABEL_PDF: "PDF",
  LABEL_XLS: "XLS",
  LABEL_CSV: "CSV",
  LABEL_JSON: "JSON",
  LABEL_EXPORT: "Preuzmi",
  LABEL_DOWNLOAD_DATA_TABLE: "Preuzmite sadržaj tabele",
  LABEL_RANK_LIST: "Rang lista",
  LABEL_UNSUBSCRIBE_TEXT: "Ukoliko ne želite više da primate obaveštenja i da se odjavite sa mejling liste kliknite ",

  TAB_ARTICLE_SUMMARY: "Osnovni podaci",
  TAB_ARTICLE_CONTENT: "Sadržaj",
  TAB_ARTICLE_PREVIEW_SUMMARY: "Kratak pregled",
  TAB_ARTICLE_PREVIEW_CONTENT: "Pregled sadržaja",

  START_TIME: "startTime",
  // Chart labels
  LABEL_COLLECTED: "Sakupio",
  LABEL_MISSING: "Fali",

  TABLE_EMPTY_MESSAGE: "Nema podataka",

  //Column title
  COLUMN_TITLE_RANK: "Rang",
  COLUMN_TITLE_CHOSEN_NAME: "Izabrano ime",
  COLUMN_TITLE_DISTANCE: "Pređeno",

  //Dialog header
  TITLE_DIALOG_CREATE_ARTICLE: "Kreiranje članka",
  TITLE_DIALOG_DELETE_ARTICLE: "Brisanje članka: ",
  TITLE_DIALOG_UPDATE_ARTICLE: "Izmena članka: ",
  TITLE_DIALOG_READ_ARTICLE: "Detalji članka: ",
  TITLE_DIALOG_CREATE_FAQ: "Kreiranje čestog pitanja",
  TITLE_DIALOG_DELETE_FAQ: "Brisanje čestog pitanja: ",
  TITLE_DIALOG_UPDATE_FAQ: "Izmena čestog pitanja: ",
  TITLE_DIALOG_READ_FAQ: "Detalji čestog pitanja: ",
  TITLE_DIALOG_CREATE_EVENT: "Kreiranje šetnje:",
  TITLE_DIALOG_DELETE_EVENT: "Brisanje šetnje: ",
  TITLE_DIALOG_UPDATE_EVENT: "Izmena šetnje: ",
  TITLE_DIALOG_READ_EVENT: "Detalji šetnje: ",
  TITLE_REGISTRATION_CREATE: "Kreiranje registracije",
  TITLE_DIALOG_CREATE_MESSAGE: "Slanje poruke",
  TITLE_DIALOG_CREATE_ACTIVITY_RANGE: "Kreiranje opsega",
  TITLE_DIALOG_READ_MESSAGE: "Detalji poruke",
  TITLE_DIALOG_READ_ACTIVITY_RANGE: "Detalji opsega",
  TITLE_DIALOG_DELETE_ACTIVITY_RANGE: "Brisanje opsega",
  TITLE_DIALOG_UPDATE_ACTIVITY_RANGE: "Izmena opsega",

  //Column header
  COLUMN_HEADER_TITLE: "Naslov",
  COLUMN_HEADER_LENGTH_M: "Dužina [m]",
  COLUMN_HEADER_MAX_PERSONS: "Najviše učesnika",
  COLUMN_HEADER_START_TIME: "Vreme početka",
  COLUMN_HEADER_EVENT_STATUS: "Status događaja",
  COLUMN_HEADER_ON_CARUSEL: "Na karuselu",
  COLUMN_HEADER_CHOSEN_NAME: "Izabrano ime",
  COLUMN_HEADER_REGISTRATION_CODE: "Šifra prijave",
  COLUMN_HEADER_EMAIL: "Email korisnika",
  COLUMN_HEADER_REGISTRATION_TIME: "Vreme registracije",
  COLUMN_HEADER_SEND_TIME: "Vreme slanja",
  COLUMN_HEADER_EMAIL_CONTENT: "Sadržaj email-a",
  COLUMN_HEADER_NOTIFICATION_CONTENT: "Sadržaj notifikacije",
  COLUMN_HEADER_NAME: "Naziv",
  COLUMN_HEADER_DATE_FROM: "Datum od",
  COLUMN_HEADER_DATE_TO: "Datum do",
  COLUMN_HEADER_GOAL_METERS: "Cilj",

  //Tooltip
  TOOLTIP_EXPORT_PDF: "PDF export",

  //Title messages
  TITLE_MESSAGES_REGISTRATION_ERROR: "Greška prilikom registracije ",
  TITLE_MESSAGES_RESET_PASSWORD: "Greška prilikom reseta lozinke ",
  TITLE_MESSAGES_ERROR: "Greška",
  TITLE_MESSAGES_CREATE_EVENT_SUCCESS: "Uspešno kreiran događaj",
  TITLE_MESSAGES_CREATE_MESSAGE_SUCCESS: "Uspešno poslata poruka",
  TITLE_MESSAGES_CREATE_ACTIVITY_RANGE_SUCCESS: "Uspešno kreiran opseg aktivnosti",
  TITLE_MESSAGES_DELETE_ACTIVITY_RANGE_SUCCESS: "Uspešno izbrisan opseg aktivnosti",
  TITLE_MESSAGES_UPDATE_ACTIVITY_RANGE_SUCCESS: "Uspešno izmenjen opseg aktivnosti",
  TITLE_MESSAGES_GENERATE_ACTIVITY_RANGE_DETAILS_SUCCESS: "Uspešno generisani podaci",
  TITLE_MESSAGES_UPDATE_EVENT_SUCCESS: "Uspešno izmenjen događaj",
  TITLE_MESSAGES_DELETE_EVENT_SUCCESS: "Uspešno izbrisan događaj",
  TITLE_MESSAGES_CREATE_ARTICLE_SUCCESS: "Uspešno kreiran članak",
  TITLE_MESSAGES_UPDATE_ARTICLE_SUCCESS: "Uspešno izmenjen članak",
  TITLE_MESSAGES_DELETE_ARTICLE_SUCCESS: "Uspešno izbrisan članak",
  TITLE_MESSAGES_UPDATE_USER_SUCCESS: "Uspešno izmenjen korisnik ",
  TITLE_MESSAGES_CREATE_ERROR: "Greška prilikom kreiranja ",
  TITLE_MESSAGES_UPDATE_ERROR: "Greška prilikom izmene ",
  TITLE_MESSAGES_DELETE_ERROR: "Greška prilikom brisanja ",
  TITLE_MESSAGES_UPLOAD_ERROR: "Greška prilikom učitavanja datoteke",
  TITLE_MESSAGES_CREATE_FAQ_SUCCESS: "Uspešno kreirao pitanje",
  TITLE_MESSAGES_UPDATE_FAQ_SUCCESS: "Uspešno izmenjeno pitanje",
  TITLE_MESSAGES_DELETE_FAQ_SUCCESS: "Uspešno izbrisano pitanje",
  TITLE_MESSAGE_REGISTERED_USER_ZERO: "Nema registrovanih korisnika za dati događaj",

  //Messages
  MESSAGES_FIRSTNAME_FIELD: "Polje 'Ime' je obavezno",
  MESSAGES_LASTNAME_FIELD: "Polje 'Prezime' je obavezno",
  MESSAGES_CHOSEN_NAME_FIELD: "Polje 'Izabrano ime' je obavezno",
  MESSAGES_EMAIL_FIELD: "Polje 'Email' je obavezno",
  MESSAGES_PASSWORD_FIELD: "Polje 'Lozinka' je obavezno",
  MESSAGES_PASSWORD_CONFIRM_FIELD: "Polje 'Potvrda lozinke' je obavezno",
  MESSAGES_PASSWORD_LENGTH: "Lozinka mora sadržati najmanje 8 alfa-numeričkih karaktera",
  MESSAGES_NOT_EQUAL_PASSWORD: "Lozinka i potvrda lozinke moraju biti iste",
  MESSAGES_UPLOAD_SIZE_50KB_ERROR: "Datoteka mora biti manja od 50 Kb.",
  MESSAGES_CHOOSE_IMAGE: "Izaberite fotografiju",
  MESSAGES_FILE_SIZE_ERROR: "Maksimalna veličina slike je 5MB",
  MESSAGES_UPLOAD_IMAGE_ERROR: "Neuspešno učitvanje slike",
  MESSAGES_REGISTRATION_RESEND: "Ukoliko Vam nije stigao mejl, kliknite na dugme Pošalji opet mejl",
  MESSAGES_FILE_SIZE_64KB_ERROR: "Maksimalna veličina slike je 64KB",
  MESSAGES_FILE_SIZE_400KB_ERROR: "Maksimalna veličina slike je 400KB",
  MESSAGES_FILE_SIZE_5MB_ERROR: "Maksimalna veličina slike je 5MB",
  MESSAGES_CREATE_REGISTRATION_SUCCESS: "Uspešno kreirana registracija za korisnika",

  // LoginPage
  LOGINPAGE_INFO_01: "Svakih 8 sekundi neko umre od dijabetesa",
  LOGINPAGE_INFO_02: "Svakih 5 minuta neko sa dijabetesom doživi srčani udar",
  LOGINPAGE_INFO_03: "Svakih 30 sekundi neko podlegne amputaciji ekstremiteta zbog dijabetesa",
  LOGINPAGE_INFO_04: "90% slučajeva dijabetesa tip 2 je moglo biti prevenirano",
  LOGINPAGE_INFO_05: "Vreme je za akciju",

  // UserProfile page
  USER_PROFILE_EDIT: "Izmena korisničkog profila",
  USER_PROFILE_EMAIL: "Email",
  USER_PROFILE_CHOSENNAME: "Izabrano ime",
  USER_PROFILE_DATE_OF_BIRTH: "Datum rođenja",
  USER_PROFILE_GENDER: "Pol",
  USER_PROFILE_GENDER_MALE: "Muški",
  USER_PROFILE_GENDER_FEMALE: "Ženski",
  USER_PROFILE_HEIGHT: "Visina",
  USER_PROFILE_WEIGHT: "Težina",
  USER_PROFILE_SAVE_CHANGES: "Sačuvaj izmene",
  USER_PROFILE_EDIT_SUCCESS: "Uspešno sačuvane izmene.",

  // Statistics
  STATISTICS_USER_ID: "ID korisnika",
  STATISTICS_USER_CHOSEN_NAME: "Izabrano ime",
  STATISTICS_USER_BIRTH_DATE: "Datum rođenja korisnika",
  STATISTICS_USER_HEIGHT: "Visina korisnika",
  STATISTICS_USER_WIDTH: "Težina korisnika",
  STATISTICS_USER_KILOMETERES_TRAVELED: "Pređeni kilometri",
  STATISTICS_NUMBER_OF_RESULTS: "Broj rezultata: ",
  STATISTICS_GENERATE_DATATABLE: "Generiši",
};

export default Labels;
