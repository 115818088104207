import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState, useRef } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig, DATE_FORMAT, handleAxiosCallError } from "../../infrastructure/Utils";
import { AppContext } from "../../Store";
import moment from "moment";
import ActivityRangeReadDto from "../../model/activity-range/ActivityRangeReadDto";
import CrudActivityRange from "./CrudActivityRange";
import "moment/locale/sr";

export default function CrudActivityRangeList() {
  const [activityRangeList, setActivityRangeList] = useState<Array<ActivityRangeReadDto>>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const { authData, showMessage, setShowBlockUI } = useContext(AppContext);

  const [entityOperation, setEntityOperation] = useState<any>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [activityRangeToChange, setActivityRangeToChange] = useState<ActivityRangeReadDto>();
  const [selectedActivityRange, setSelectedActivityRange] = useState<ActivityRangeReadDto | null>();
  const dialogRef = useRef<any>();
  let dt = useRef<any>(null);

  const [tableRows, setTableRows] = useState(10);
  const [first, setFirst] = useState(0);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedActivityRange?: number) => {
    setShowBlockUI(true);
    const requestActivityRangeList = axios.get(Endpoint.ACTIVITY_RANGE, axiosConfig(authData?.token));
    axios
      .all([requestActivityRangeList])
      .then(
        axios.spread((responseActivityRangeList) => {
          setActivityRangeList(responseActivityRangeList.data.data);
          setShowBlockUI(false);
          if (idSelectedActivityRange) {
            responseActivityRangeList.data.data.forEach((activityRange: any) => {
              if (idSelectedActivityRange === activityRange.id) {
                setSelectedRow(activityRange);
                setSelectedActivityRange(activityRange);
              }
            });
          }
        })
      )
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
        setShowBlockUI(false);
      });
  };

  const createActivityRange = (activityRange: ActivityRangeReadDto) => {
    setShowBlockUI(true);
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.ACTIVITY_RANGE, activityRange, axiosConfig(authData.token))
        .then((response: any) => {
          closeDialog();
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_CREATE_ACTIVITY_RANGE_SUCCESS, "");
          fetchData(response.data.data.id);
          setShowBlockUI(false);
          resolve();
        })
        .catch((error: any) => {
          setShowBlockUI(false);
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const updateActivityRange = (activityRange: ActivityRangeReadDto) => {
    setShowBlockUI(true);
    return new Promise<void>((resolve, reject) => {
      axios
        .put(`${Endpoint.ACTIVITY_RANGE}/${activityRange.id}`, activityRange, axiosConfig(authData.token))
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_UPDATE_ACTIVITY_RANGE_SUCCESS, "");
          fetchData(response.data.data.id);
          setShowBlockUI(false);
          resolve();
        })
        .catch((error: any) => {
          setShowBlockUI(false);
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const deleteActivityRange = (activityRangeID: number) => {
    setShowBlockUI(false);
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(`${Endpoint.ACTIVITY_RANGE}/${activityRangeID}`, axiosConfig(authData.token))
        .then(() => {
          setDisplayDialog(false);
          setSelectedActivityRange(null);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_DELETE_ACTIVITY_RANGE_SUCCESS, "");
          fetchData();
          setShowBlockUI(false);
          resolve();
        })
        .catch((error: any) => {
          setShowBlockUI(false);
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  function openDialog(entityOperation: String, messageToChange?: ActivityRangeReadDto) {
    let u = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        u = messageToChange;
        break;
    }

    setEntityOperation(entityOperation);
    setActivityRangeToChange(u);
    setDisplayDialog(true);
  }

  function closeDialog() {
    setDisplayDialog(false);
  }

  function onCancel() {
    closeDialog();
  }

  const goalKilometersBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_GOAL_METERS}</span>
        {rowData.goalKilometers}
      </React.Fragment>
    );
  };

  const nameBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_NAME}</span>
        <div className="p-text-truncate">{rowData.name}</div>
      </React.Fragment>
    );
  };

  const dateFromBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_DATE_FROM}</span>
        <div className="p-text-truncate">{moment(rowData.dateFrom).format(DATE_FORMAT)}</div>
      </React.Fragment>
    );
  };

  const dateToBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_DATE_TO}</span>
        <div className="p-text-truncate">{moment(rowData.dateTo).format(DATE_FORMAT)}</div>
      </React.Fragment>
    );
  };

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          icon="pi pi-save"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          icon="pi pi-inbox"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          icon="pi pi-inbox"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </React.Fragment>
  );

  const rightContentsDialog = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={() => onCancel()} />
    </React.Fragment>
  );

  const leftContents = () => (
    <React.Fragment>
      <Button
        className="toolbar-button"
        icon="pi pi-plus"
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />
      <Button
        className="toolbar-button"
        disabled={!selectedActivityRange}
        icon="pi pi-pencil"
        onClick={() => {
          selectedActivityRange && openDialog(EntityOperation.UPDATE, selectedActivityRange);
        }}
      />
      <Button
        className="toolbar-button"
        disabled={!selectedActivityRange}
        icon="pi pi-info-circle"
        onClick={() => {
          selectedActivityRange && openDialog(EntityOperation.READ, selectedActivityRange);
        }}
      />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button
        disabled={!selectedActivityRange}
        icon="pi pi-trash"
        onClick={() => {
          selectedActivityRange && openDialog(EntityOperation.DELETE, selectedActivityRange);
        }}
      />
    </React.Fragment>
  );

  const dialogHeader = (entityOperation: String) => {
    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_CREATE_ACTIVITY_RANGE;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_READ_ACTIVITY_RANGE;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_UPDATE_ACTIVITY_RANGE;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_DELETE_ACTIVITY_RANGE;
      default:
        return "";
    }
  };

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  return (
    <div className="layout-message">
      <Panel header={Labels.LABEL_ACTIVITY_RANGE}>
        <Toolbar left={leftContents} right={rightContents} />
        <div className="datatable-responsive">
          <DataTable
            ref={dt}
            value={activityRangeList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              setTableRows(e.rows);
              setFirst(e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedActivityRange(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            className="p-datatable-responsive"
          >
            <Column className="td-left column-width-150" field={"dateFrom"} header={Labels.COLUMN_HEADER_DATE_FROM} filter filterMatchMode="contains" sortable body={dateFromBodyTemplate} />
            <Column className="td-left column-width-150" field={"dateTo"} header={Labels.COLUMN_HEADER_DATE_TO} filter filterMatchMode="contains" sortable body={dateToBodyTemplate} />
            <Column className="td-left" field={"name"} header={Labels.COLUMN_HEADER_NAME} filter filterMatchMode="contains" sortable body={nameBodyTemplate} />
            <Column
              className="td-left column-width-150"
              field={"goalKilometers"}
              header={Labels.COLUMN_HEADER_GOAL_METERS}
              filter
              filterMatchMode="contains"
              sortable
              body={goalKilometersBodyTemplate}
            />
          </DataTable>
        </div>
        <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
          <CrudActivityRange
            activityRangeOperation={entityOperation}
            onDeleteActivityRange={deleteActivityRange}
            onCreateActivityRange={createActivityRange}
            onUpdateActivityRange={updateActivityRange}
            activityRange={activityRangeToChange}
            onCancel={onCancel}
            dialogRef={dialogRef}
          />
        </Dialog>
      </Panel>
    </div>
  );
}
