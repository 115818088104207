import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState, useRef } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig, DATE_TIME_FORMAT, handleAxiosCallError } from "../../infrastructure/Utils";
import { AppContext } from "../../Store";
import moment from "moment";
import MessageReadDto from "../../model/message/messageReadDto";
import CrudMessage from "./CrudMessage";

export default function CrudMessageList() {
  const [messageList, setMessageList] = useState<Array<MessageReadDto>>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const { authData, showMessage, setShowBlockUI } = useContext(AppContext);

  const [entityOperation, setEntityOperation] = useState<any>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [messageToChange, setMessageToChange] = useState<MessageReadDto>();
  const [selectedMessage, setSelectedMessage] = useState<MessageReadDto | null>();
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [userList, setUserList] = useState([]);
  const dialogRef = useRef<any>();
  let dt = useRef<any>(null);

  const [tableRows, setTableRows] = useState(10);
  const [first, setFirst] = useState(0);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedMessage?: number) => {
    setShowBlockUI(true);
    const requestMessageList = axios.get(Endpoint.MESSAGE_LIST, axiosConfig(authData?.token, { size: 50000, page: 0 }));
    const requestUserList = axios.get(Endpoint.USER_LIST, axiosConfig(authData?.token));
    axios
      .all([requestMessageList, requestUserList])
      .then(
        axios.spread((responseMessageList, requestUserList) => {
          setUserList(
            requestUserList.data.data.map((user: any) => {
              return { name: user.chosenName + " - " + user.email, code: user.id };
            })
          );
          setMessageList(responseMessageList.data.data);
          setShowBlockUI(false);
          if (idSelectedMessage) {
            responseMessageList.data.data.forEach((event: any) => {
              if (idSelectedMessage === event.id) {
                setSelectedRow(event);
                setSelectedMessage(event);
              }
            });
          }
        })
      )
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
        setShowBlockUI(false);
      });
  };

  const createMessage = (message: MessageReadDto) => {
    setShowBlockUI(true);
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.MESSAGE_LIST, message, axiosConfig(authData.token))
        .then((response: any) => {
          closeDialog();
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_CREATE_MESSAGE_SUCCESS, "");
          fetchData(response.data.data.id);
          setShowBlockUI(false);
          resolve();
        })
        .catch((error: any) => {
          setShowBlockUI(false);
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  function openDialog(entityOperation: String, messageToChange?: MessageReadDto) {
    let u = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        u = messageToChange;
        break;
    }

    setEntityOperation(entityOperation);
    setMessageToChange(u);
    setDisplayDialog(true);
  }

  function closeDialog() {
    setDisplayDialog(false);
    setSelectedUserList([]);
  }

  function onCancel() {
    closeDialog();
  }

  const insertTimestampBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_SEND_TIME}</span>
        {moment(rowData.insertTimestamp).format(DATE_TIME_FORMAT)}
      </React.Fragment>
    );
  };

  const emailTextBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_EMAIL_CONTENT}</span>
        <div className="p-text-truncate">{rowData.emailText}</div>
      </React.Fragment>
    );
  };

  const notificationTextBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_NOTIFICATION_CONTENT}</span>
        <div className="p-text-truncate">{rowData.notificationText}</div>
      </React.Fragment>
    );
  };

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_SEND_MESSAGE}
          icon="pi pi-send"
          onClick={() => {
            dialogRef.current.onCreateMess();
          }}
        />
      )}
    </React.Fragment>
  );

  const rightContentsDialog = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={() => onCancel()} />
    </React.Fragment>
  );

  const leftContents = () => (
    <React.Fragment>
      <Button
        className="toolbar-button"
        icon="pi pi-plus"
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        className="toolbar-button"
        disabled={!selectedMessage}
        icon="pi pi-info-circle"
        onClick={() => {
          selectedMessage && openDialog(EntityOperation.READ, selectedMessage);
        }}
      />
    </React.Fragment>
  );

  const dialogHeader = (entityOperation: String) => {
    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_CREATE_MESSAGE;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_READ_MESSAGE + Labels.DASH + (messageToChange && moment(messageToChange.insertTimestamp).format(DATE_TIME_FORMAT));
      default:
        return "";
    }
  };

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  return (
    <div className="layout-message">
      <Panel header={Labels.MENU_MESSAGE_LIST}>
        <Toolbar left={leftContents} />
        <div className="datatable-responsive">
          <DataTable
            ref={dt}
            value={messageList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              setTableRows(e.rows);
              setFirst(e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedMessage(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            className="p-datatable-responsive"
          >
            <Column className="td-left" field={"notificationText"} header={Labels.COLUMN_HEADER_NOTIFICATION_CONTENT} filter filterMatchMode="contains" sortable body={notificationTextBodyTemplate} />
            <Column className="td-left" field={"emailText"} header={Labels.COLUMN_HEADER_EMAIL_CONTENT} filter filterMatchMode="contains" sortable body={emailTextBodyTemplate} />
            <Column className="td-left column-width-200" field={"insertTimestamp"} header={Labels.COLUMN_HEADER_SEND_TIME} sortable body={insertTimestampBodyTemplate} />
          </DataTable>
        </div>
        <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1100px" }} footer={dialogFooter()}>
          <CrudMessage
            selectedUserList={selectedUserList}
            setSelectedUserList={setSelectedUserList}
            userList={userList}
            onCreateMessage={createMessage}
            messageOperation={entityOperation}
            message={messageToChange}
            onCancel={onCancel}
            dialogRef={dialogRef}
            visibleRecipientTab={true}
          />
        </Dialog>
      </Panel>
    </div>
  );
}
