const Images = {
  LOGO_ON_WHITE: require("./images/logo/logo-on-white.png"),
  LOGO_ON_DARK: require("./images/logo/logo-on-dark.png"),

  PARTNER_GRADOVI_MENJAJU_DIJABETES: require("./images/partner/partner-gradoviMenjajuDijabetes.png"),

  TWITTER: require("./images/twitter.png"),

  profile: require("./images/profile.png"),
  no_profile: require("./images/no_profile.png"),
  profilePicture: require("./images/avatar_1.png"),
  profilePicture2: require("./images/avatar_2.png"),
  profilePicture3: require("./images/avatar_3.png"),
  map: require("./images/mapa.png"),
  maxi: require("./images/maxi.png"),
  telenor: require("./images/telenor.png"),
  check: require("./images/check.png"),
  red_cross: require("./images/red-cross.png"),
  googlePlay: require("./images/googlePlay.png"),
  appStore: require("./images/appStore.png"),
  START_MARKER: require("./images/start.png"),
};

export default Images;
