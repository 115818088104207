import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState, useRef, useEffect } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig, DATE_TIME_FORMAT, handleAxiosCallError } from "../../infrastructure/Utils";
import EventCreateDto from "../../model/event/EventCreateDto";
import EventReadDto from "../../model/event/EventReadDto";
import EventUpdateDto from "../../model/event/EventUpdateDto";
import { AppContext } from "../../Store";
import CrudEvent from "./CrudEvent";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import CrudMessage from "../message/CrudMessage";
import MessageReadDto from "../../model/message/messageReadDto";

export default function CrudEventList() {
  const [eventList, setEventList] = useState<Array<EventReadDto>>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const { authData, showMessage, setShowBlockUI } = useContext(AppContext);

  const [eventStatusList, setEventStatusList] = useState([]);
  const [selectedEventStatus, setSelectedEventStatus] = useState();
  const [entityOperation, setEntityOperation] = useState<any>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [eventToChange, setEventToChange] = useState<EventReadDto>();
  const [selectedEvent, setSelectedEvent] = useState<EventReadDto | null>();
  const [displayMessageDialog, setDisplayMessageDialog] = useState(false);
  const [message, setMessage] = useState<any>({ isNotification: false, isEmail: false });
  const dialogRef = useRef<any>();
  let dt = useRef<any>(null);

  const [tableRows, setTableRows] = useState(10);
  const [first, setFirst] = useState(0);

  useEffectOnce(() => {
    const requestEventStatusList = axios.get(Endpoint.EVENT_STATUS_LIST, axiosConfig(authData.token));
    axios
      .all([requestEventStatusList])
      .then(
        axios.spread((responseEventStatusList) => {
          const eventStatus = responseEventStatusList.data.map((eventStatus: any) => {
            return { label: eventStatus.name, value: eventStatus.name };
          });
          setEventStatusList(eventStatus);
        })
      )
      .catch((error: any) => {});
    fetchData();
  });

  const fetchData = (idSelectedEvent?: number) => {
    setShowBlockUI(true);
    const requestEventList = axios.get(Endpoint.EVENT_LIST, axiosConfig(authData?.token, { size: 50000, page: 0 }));
    axios
      .all([requestEventList])
      .then(
        axios.spread((responseEventList) => {
          setEventList(responseEventList.data.data);
          setShowBlockUI(false);
          if (idSelectedEvent) {
            responseEventList.data.data.forEach((event: any) => {
              if (idSelectedEvent === event.id) {
                setSelectedRow(event);
                setSelectedEvent(event);
              }
            });
          }
        })
      )
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
        setShowBlockUI(false);
      });
  };

  const createEvent = (event: EventCreateDto) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${Endpoint.EVENT}/eventList`, event, axiosConfig(authData.token))
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_CREATE_EVENT_SUCCESS, "");
          fetchData(response.data.data.id);
          resolve();
        })
        .catch((error: any) => {
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const updateEvent = (event: EventUpdateDto) => {
    const newEvent = { ...event, startTime: moment(event.startTime).utc(true).format() };
    return new Promise<void>((resolve, reject) => {
      axios
        .put(`${Endpoint.EVENT}/eventList/${event.id}`, newEvent, axiosConfig(authData.token))
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_UPDATE_EVENT_SUCCESS, "");
          fetchData(response.data.data.id);
          resolve();
        })
        .catch((error: any) => {
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const deleteEvent = (eventID: number) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(`${Endpoint.EVENT}/eventList/${eventID}`, axiosConfig(authData.token))
        .then(() => {
          setDisplayDialog(false);
          setSelectedEvent(null);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_DELETE_EVENT_SUCCESS, "");
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  function openDialog(entityOperation: String, eventToChange?: EventReadDto) {
    let u = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        u = eventToChange;
        break;
    }

    setEntityOperation(entityOperation);
    setEventToChange(u);
    setDisplayDialog(true);
  }

  function openMessageDialog() {
    const requstRegistrationList = axios.get(`${Endpoint.EVENT_LIST}/${selectedEvent?.id}/registrationList`, axiosConfig(authData.token));
    axios.all([requstRegistrationList]).then(
      axios.spread((responseRegistrationList) => {
        if (responseRegistrationList.data.data.length === 0) {
          showMessage(MessageType.WARNING, Labels.TITLE_MESSAGE_REGISTERED_USER_ZERO, "");
        } else {
          const registrationUserIdList = responseRegistrationList.data.data.map((registration: any) => registration.user.id);
          setMessage({ ...message, userIDList: registrationUserIdList });
        }
      })
    );
  }

  useEffect(() => {
    if (message && message.userIDList) {
      setDisplayMessageDialog(true);
    }
  }, [message]);

  function closeDialog() {
    setDisplayDialog(false);
  }

  function onCancel() {
    closeDialog();
  }

  const startTimeBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_START_TIME}</span>
        {moment(rowData.startTime).format(DATE_TIME_FORMAT)}
      </React.Fragment>
    );
  };

  const eventStatusBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_EVENT_STATUS}</span>
        {rowData.eventStatus.name}
      </React.Fragment>
    );
  };

  const titleBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_TITLE}</span>
        {rowData.title}
      </React.Fragment>
    );
  };

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          icon="pi pi-save"
          onClick={() => {
            dialogRef.current.takeSnapshot();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          icon="pi pi-inbox"
          onClick={() => {
            dialogRef.current.takeSnapshot();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          icon="pi pi-inbox"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </React.Fragment>
  );

  const rightContentsDialog = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={() => onCancel()} />
    </React.Fragment>
  );

  const leftContents = () => (
    <React.Fragment>
      <Button
        className="toolbar-button"
        icon="pi pi-plus"
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        className="toolbar-button"
        disabled={!selectedEvent}
        icon="pi pi-pencil"
        onClick={() => {
          selectedEvent && openDialog(EntityOperation.UPDATE, selectedEvent);
        }}
      />
      <Button
        className="toolbar-button"
        disabled={!selectedEvent}
        icon="pi pi-info-circle"
        onClick={() => {
          selectedEvent && openDialog(EntityOperation.READ, selectedEvent);
        }}
      />
      <Button
        className="toolbar-button"
        disabled={!selectedEvent}
        icon="pi pi-send"
        onClick={() => {
          selectedEvent && openMessageDialog();
        }}
      />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button
        disabled={!selectedEvent}
        icon="pi pi-trash"
        onClick={() => {
          selectedEvent && openDialog(EntityOperation.DELETE, selectedEvent);
        }}
      />
    </React.Fragment>
  );

  const dialogHeader = (entityOperation: String) => {
    const display = eventToChange ? eventToChange.title : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_CREATE_EVENT;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_DELETE_EVENT + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_UPDATE_EVENT + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_READ_EVENT + display;
      default:
        return "";
    }
  };

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  const onEventStatusFilterChange = (e: any) => {
    dt.current.filter(e.value, "eventStatus.name", "equals");
    setSelectedEventStatus(e.value);
  };

  const renderEventStatusFilter = () => {
    return (
      <Dropdown
        value={selectedEventStatus}
        options={eventStatusList}
        onChange={onEventStatusFilterChange}
        showClear
        placeholder={Labels.PLACEHOLDER_CHOOSE_STATUS}
        className="p-column-filter column-width-100"
      />
    );
  };

  const createMessage = (message: MessageReadDto) => {
    setShowBlockUI(true);
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.MESSAGE_LIST, message, axiosConfig(authData.token))
        .then((response: any) => {
          setDisplayMessageDialog(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_CREATE_MESSAGE_SUCCESS, "");
          setShowBlockUI(false);
          resolve();
        })
        .catch((error: any) => {
          setShowBlockUI(false);
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const dialogMessageFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsMessageDialog} right={rightContentsMessageDialog} />
      </div>
    );
  };

  const rightContentsMessageDialog = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={() => setDisplayMessageDialog(false)} />
    </React.Fragment>
  );

  const leftContentsMessageDialog = () => (
    <React.Fragment>
      <Button
        label={Labels.BUTTON_SEND_MESSAGE}
        icon="pi pi-send"
        onClick={() => {
          dialogRef.current.onCreateMess();
        }}
      />
    </React.Fragment>
  );

  const eventStatusFilter = renderEventStatusFilter();

  return (
    <div className="layout-event">
      <Panel header={Labels.MENU_EVENT_LIST}>
        <Toolbar left={leftContents} right={rightContents} />
        <div className="datatable-responsive">
          <DataTable
            ref={dt}
            value={eventList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              setTableRows(e.rows);
              setFirst(e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedEvent(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            className="p-datatable-responsive"
          >
            <Column
              className="td-left column-width-250"
              field={"eventStatus.name"}
              header={Labels.COLUMN_HEADER_EVENT_STATUS}
              filterElement={eventStatusFilter}
              filter
              filterMatchMode="custom"
              body={eventStatusBodyTemplate}
            />
            <Column className="td-left" field={"title"} header={Labels.COLUMN_HEADER_TITLE} filter filterMatchMode="contains" sortable body={titleBodyTemplate} />
            <Column className="td-left column-width-200" field={"startTime"} header={Labels.COLUMN_HEADER_START_TIME} sortable body={startTimeBodyTemplate} />
          </DataTable>
        </div>
        <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1100px" }} footer={dialogFooter()}>
          <CrudEvent
            onCreateEvent={createEvent}
            onUpdateEvent={updateEvent}
            onDeleteEvent={deleteEvent}
            eventOperation={entityOperation}
            event={eventToChange}
            onCancel={onCancel}
            dialogRef={dialogRef}
          />
        </Dialog>
        <Dialog
          header={Labels.SEND_MESSAGE_TO_REGISTERED_USER_LIST}
          visible={displayMessageDialog}
          onHide={() => {
            setDisplayMessageDialog(false);
          }}
          style={{ width: "1100px" }}
          footer={dialogMessageFooter()}
        >
          <CrudMessage
            message={message}
            onCreateMessage={createMessage}
            messageOperation={EntityOperation.CREATE}
            onCancel={() => {
              setDisplayMessageDialog(false);
            }}
            dialogRef={dialogRef}
            visibleRecipientTab={false}
          />
        </Dialog>
      </Panel>
    </div>
  );
}
