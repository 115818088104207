import React from "react";

interface ParamProps {
  leadingSeparator: boolean;
  firstLine: string;
  secondLine: string;
}

export default function TwoLineText({ leadingSeparator, firstLine, secondLine }: ParamProps) {
  return (
    <div className="layout-common">
      <div className={leadingSeparator ? "p-grid p-dir-column p-nogutter p-align-center two-line-text-border" : "p-grid p-dir-column p-nogutter p-align-center"}>
        <div className="p-grid p-dir-column p-nogutter p-align-center">
          <div className="p-col-12 two-line-text-first font-size-small">{firstLine}</div>
          <div className="p-col-12 two-line-text-second font-size-medium text-bold">{secondLine}</div>
        </div>
      </div>
    </div>
  );
}
