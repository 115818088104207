import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { getImage } from "../../infrastructure/Utils";
import UserReadDto from "../../model/user/UserReadDto";
import { AppContext } from "../../Store";
import { Avatar } from "primereact/avatar";
import { confirmDialog } from "primereact/confirmdialog";

interface AppTopbarProps {
  currentUser: UserReadDto;
}

export default function AppTopbar(props: AppTopbarProps) {
  const { currentUser } = props;

  const { deleteAuthData } = useContext(AppContext);
  const [selectedButton, setSelectedButton] = useState<string>();
  let location = useLocation();
  const history = useHistory();
  const menuHeaderModel = [
    {
      label: Labels.MENU_RANK_LIST,
      command: () => {
        setSelectedButton(Labels.MENU_RANK_LIST);
        history.push("/rankList");
      },
    },
    {
      label: Labels.MENU_ARTICLE_LIST,
      command: () => {
        setSelectedButton(Labels.MENU_ARTICLE_LIST);
        history.push("/articleList");
      },
    },
    {
      label: Labels.MENU_FAQ_LIST,
      command: () => {
        setSelectedButton(Labels.MENU_FAQ_LIST);
        history.push("/faqList");
      },
    },
    {
      label: Labels.MENU_EVENT_LIST,
      command: () => {
        setSelectedButton(Labels.MENU_EVENT_LIST);
        history.push("/eventList");
      },
    },
    {
      label: Labels.MENU_MESSAGE_LIST,
      command: () => {
        setSelectedButton(Labels.MENU_MESSAGE_LIST);
        history.push("/messageList");
      },
    },
    {
      label: Labels.MENU_CHART_LIST,
      command: () => {
        setSelectedButton(Labels.MENU_CHART_LIST);
        history.push("/messageList");
      },
    },
    {
      label: Labels.MENU_STATISTICAL_DATA,
      command: () => {
        setSelectedButton(Labels.MENU_STATISTICAL_DATA);
        history.push("/statisticalData");
      },
    },
    {
      label: Labels.BUTTON_LOGOUT,
      command: () => {
        logOutDialog();
      },
    },
  ];
  const menuHeaderUserModel = [
    {
      items: [
        {
          label: currentUser.chosenName,
          icon: "pi pi-user-edit",
          command: () => {
            history.push("/user");
          },
        },
        {
          label: Labels.BUTTON_LOGOUT,
          icon: "pi pi-power-off",
          command: () => {
            logOutDialog();
          },
        },
      ],
    },
  ];
  let menuHeader: any = null;
  let menuHeaderUser: any = null;

  useEffect(() => {
    let path = location.pathname;
    if (path.substr(location.pathname.lastIndexOf("/") + 1) === "faqList") {
      setSelectedButton(Labels.MENU_FAQ_LIST);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "rankList") {
      setSelectedButton(Labels.MENU_RANK_LIST);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "eventList") {
      setSelectedButton(Labels.MENU_EVENT_LIST);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "articleList") {
      setSelectedButton(Labels.MENU_ARTICLE_LIST);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "messageList") {
      setSelectedButton(Labels.MENU_MESSAGE_LIST);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "chartList") {
      setSelectedButton(Labels.MENU_CHART_LIST);
    } else if (path.substr(location.pathname.lastIndexOf("/") + 1) === "statisticalData") {
      setSelectedButton(Labels.MENU_STATISTICAL_DATA);
    } else {
      setSelectedButton(Labels.MENU_FAQ_LIST);
    }
  }, [location]);

  const logOutDialog = () => {
    confirmDialog({
      acceptLabel: Labels.BUTTON_LOGOUT,
      rejectLabel: Labels.BUTTON_CANCEL,
      message: Labels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED,
      icon: "pi pi-power-off",
      accept: () => {
        deleteAuthData();
        history.push("/");
      },
    });
  };
  return (
    <div className="layout-header">
      <div className="min-width-801">
        <div className="p-grid p-nogutter p-justify-center p-align-center p-col-align-center full-width">
          <div className="p-col-11">
            <div className="p-grid p-nogutter p-justify-center p-align-center">
              <NavLink to="/rankList">
                <div className={`item-style ${selectedButton === Labels.MENU_RANK_LIST ? "button-background " : ""}`}>{Labels.MENU_RANK_LIST}</div>
              </NavLink>
              <NavLink to="/articleList">
                <div className={`item-style ${selectedButton === Labels.MENU_ARTICLE_LIST ? "button-background " : ""}`}>{Labels.MENU_ARTICLE_LIST}</div>
              </NavLink>
              <NavLink to="/faqList">
                <div className={`item-style ${selectedButton === Labels.MENU_FAQ_LIST ? "button-background " : ""}`}>{Labels.MENU_FAQ_LIST}</div>
              </NavLink>
              <NavLink to="/eventList">
                <div className={`item-style ${selectedButton === Labels.MENU_EVENT_LIST ? "button-background " : ""}`}>{Labels.MENU_EVENT_LIST}</div>
              </NavLink>
              <NavLink to="/messageList">
                <div className={`item-style ${selectedButton === Labels.MENU_MESSAGE_LIST ? "button-background " : ""}`}>{Labels.MENU_MESSAGE_LIST}</div>
              </NavLink>
              <NavLink to="/chartList">
                <div className={`item-style ${selectedButton === Labels.MENU_CHART_LIST ? "button-background " : ""}`}>{Labels.MENU_CHART_LIST}</div>
              </NavLink>
              <NavLink to="/statisticalData">
                <div className={`item-style ${selectedButton === Labels.MENU_STATISTICAL_DATA ? "button-background " : ""}`}>{Labels.MENU_STATISTICAL_DATA}</div>
              </NavLink>
            </div>
          </div>
          <div className="p-col-1">
            <div className="p-grid p-dir-rev p-nogutter p-justify-center p-align-center">
              <div className=" user-profile-avatar">
                <Menu ref={(el) => (menuHeaderUser = el)} model={menuHeaderUserModel} popup={true} />
                <div
                  className="align-center"
                  onClick={(event) => {
                    menuHeaderUser.toggle(event);
                  }}
                >
                  <Avatar image={getImage(false, currentUser.profilePicture, Images.profile)} className="avatar-size" shape="circle" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-width-800">
        <div>
          <Menu ref={(el) => (menuHeader = el)} model={menuHeaderModel} popup={true} />
          <Button
            className="bars-icon"
            icon="pi pi-bars"
            onClick={(event) => {
              menuHeader.toggle(event);
            }}
          />
        </div>
      </div>
    </div>
  );
}
