import React, { useMemo } from "react";
import { Dropdown } from "primereact/dropdown";
import Labels from "../../Labels_sr_Latn_RS";

export default function PageDropdownTemplate() {
  const rowsPerPageDropdownTemplateMini = useMemo(() => {
    return {
      layout: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
      RowsPerPageDropdown: (options: any) => {
        const dropdownOptions = [
          { label: 5, value: 5 },
          { label: 10, value: 10 },
          { label: 20, value: 20 },
        ];
        return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} disabled={options.totalRecords === 0} />;
      },
    };
  }, []);

  const rowsPerPageDropdownTemplateMedium = useMemo(() => {
    return {
      layout: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
      RowsPerPageDropdown: (options: any) => {
        const dropdownOptions = [
          { label: 10, value: 10 },
          { label: 20, value: 20 },
          { label: 50, value: 50 },
        ];
        return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} disabled={options.totalRecords === 0} />;
      },
    };
  }, []);

  const rowsPerPageDropdownTemplateMax = useMemo(() => {
    return {
      layout: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
      RowsPerPageDropdown: (options: any) => {
        const dropdownOptions = [
          { label: 10, value: 10 },
          { label: 50, value: 50 },
          { label: Labels.LABEL_DATATABLE_ROWS_PER_PAGE_DROPWDOWN_ALL, value: options.totalRecords },
        ];
        return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} disabled={options.totalRecords === 0} />;
      },
    };
  }, [Labels.LABEL_DATATABLE_ROWS_PER_PAGE_DROPWDOWN_ALL]);

  return { rowsPerPageDropdownTemplateMini, rowsPerPageDropdownTemplateMedium, rowsPerPageDropdownTemplateMax };
}
