enum EnumActivitySport {
  WALKING = "WALKING",
  RUNNING = "RUNNING",
  HIKING = "HIKING",
  CYCLING = "CYCLING",
  INLINE_SKATING = "INLINE_SKATING",
  WALKING_PEDOMETER = "WALKING_PEDOMETER",
}

export default EnumActivitySport;
