import React from "react";
import UserReadDto from "../../model/user/UserReadDto";
import DisplaySponsorList from "../organization/DisplaySponsorList";

interface Props {
  currentUser: UserReadDto;
}

export default function AppProfile(props: Props) {

  return (
    <div>
      <div className="layout-sponsor">
        <div className="panel-sponsor">
          <DisplaySponsorList />
        </div>
      </div>
    </div>
  );
}
