import axios from "axios";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import React, { useContext, useEffect, useState } from "react";
import Endpoint from "../../infrastructure/Endpoint";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig, getImage } from "../../infrastructure/Utils";
import ActivityReadDto from "../../model/activity/ActivityReadDto";
import CommentActivityCreateDto from "../../model/comment/CommentActivityCreateDto";
import CommentReadDto from "../../model/comment/CommentReadDto";
import CommentUpdateDto from "../../model/comment/CommentUpdateDto";
import { AppContext } from "../../Store";
import Comment from "../comment/Comment";
import CommentList from "../comment/CommentList";
import ActivityHeader from "./ActivityHeader";

interface Props {
  activity: ActivityReadDto;
  onComment?: Function;
  onShare?: Function;
}

export default function DisplayEvent(props: Props) {
  const { activity } = props;
  const [displayDialog, setDisplayDialog] = useState(false);
  const { authData, showMessage } = useContext(AppContext);
  const [commentList, setCommentList] = useState<Array<CommentReadDto>>([]);
  const [comment, setComment] = useState("");
  const [commentEditId, setCommentEditId] = useState<number>();

  const fetchData = () => {
    axios
      .get(`${Endpoint.ACTIVITY}/activityList/${activity.id}/commentList`, axiosConfig(authData.token))
      .then((response: any) => {
        setCommentList(response.data.data);
      })
      .catch((error) => {
        const messages = error.response.data.errors
          .map((err: any) => {
            return err.message;
          })
          .join(" ");
        showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_ERROR, messages);
      });
  };

  useEffect(() => {
    if (!displayDialog) {
      fetchData();
    }
  }, [displayDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteComment = (id: number) => {
    axios
      .delete(`${Endpoint.COMMENT}/commentList/${id}`, axiosConfig(authData.token))
      .then((response: any) => {
        fetchData();
      })
      .catch((error) => {
        const messages = error.response.data.errors
          .map((err: any) => {
            return err.message;
          })
          .join(" ");
        showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_DELETE_ERROR, messages);
      });
  };

  const prepareEdit = (id: number, text: string) => {
    setComment(text);
    setCommentEditId(id);
  };

  const createOrEditComment = () => {
    if (comment !== "") {
      if (!commentEditId) {
        axios
          .post(`${Endpoint.COMMENT}/commentList/activity`, new CommentActivityCreateDto({ id: authData.currentUser.id }, { id: activity.id }, comment), axiosConfig(authData.token))
          .then((response: any) => {
            fetchData();
            setComment("");
          })
          .catch((error) => {
            const messages = error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ");
            showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_CREATE_ERROR, messages);
          });
      } else {
        axios
          .put(`${Endpoint.COMMENT}/commentList/${commentEditId}`, new CommentUpdateDto(commentEditId, { id: authData.currentUser.id }, comment), axiosConfig(authData.token))
          .then((response: any) => {
            fetchData();
            setComment("");
            setCommentEditId(undefined);
          })
          .catch((error) => {
            const messages = error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ");
            showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_UPDATE_ERROR, messages);
          });
      }
    }
  };

  return (
    <div className="layout-activity">
      <div className="margin-bottom-15">
        <Card>
          <div className="p-grid p-justify-center">
            <div className="p-col-12">
              <ActivityHeader activity={activity} />
            </div>
            {activity.trackSnapshotOriginal && (
              <div className="p-col-12">
                <img className="track-snapshot" src={getImage(false, activity.trackSnapshotOriginal, Images.LOGO_ON_WHITE)} alt={""} />
              </div>
            )}
          </div>
          {commentList.length > 0 && (
            <div
              onClick={() => {
                setDisplayDialog(true);
              }}
              className="view-comments"
            >
              {Labels.LABEL_VIEW_COMMENTS} ({commentList.length})
            </div>
          )}
          {commentList.slice(0, 2).map((comment: CommentReadDto) => {
            return <Comment horizontalLine={false} comment={comment} activity={activity} key={comment.id} onEditComment={prepareEdit} onDeleteComment={deleteComment} />;
          })}
          <Dialog className="dialog-width" header={activity.description} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
            <CommentList activity={activity} />
          </Dialog>
        </Card>
      </div>
    </div>
  );
}
