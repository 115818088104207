import { Messages } from "primereact/messages";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState, useImperativeHandle } from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { isFormDisabled, toolbar } from "../../infrastructure/Utils";
import MessageReadDto from "../../model/message/messageReadDto";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import { TabPanel, TabView } from "primereact/tabview";
import { MultiSelect } from "primereact/multiselect";
import { Editor } from "primereact/editor";

interface CrudMessageProp {
  messageOperation: string;
  message?: MessageReadDto;
  onCancel: Function;
  onCreateMessage: Function;
  dialogRef?: any;
  userList?: any;
  selectedUserList?: any;
  setSelectedUserList?: any;
  visibleRecipientTab?: boolean;
}

export default function CrudMessage(prop: CrudMessageProp) {
  const { selectedUserList, setSelectedUserList, userList, messageOperation, message, onCreateMessage, dialogRef, visibleRecipientTab } = prop;
  const renderSubscribeText = `<br><br><h6>${Labels.LABEL_UNSUBSCRIBE_TEXT}<a href="${(window as any).REACT_APP_WEBSITE_URL}/unsubscribe" target="_blank"> ovde</a>.</h6>`;
  const [messageChange, setMessageChange] = useState<any>(message ? message : { isNotification: false, isEmail: false, emailText: renderSubscribeText });
  const isDisabled = isFormDisabled(messageOperation);

  let [messages, setMessages] = useState<any>("");

  useImperativeHandle(dialogRef, () => ({
    onCreateMess: () => {
      onCreateMessage(messageChange)
        .then(() => {})
        .catch((error: any) => {
          showError("", error);
        });
    },
  }));

  const showError = (summary: string, detail: string) => {
    messages.replace({
      severity: "error",
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  return (
    <div className=" layout-message layout-common">
      <div className="p-col-12 padding-0">
        <Messages
          ref={(el: any) => {
            setMessages(el);
          }}
        ></Messages>
      </div>
      <TabView>
        <TabPanel header={Labels.LABEL_EMAIL}>
          <div className="scrollable-content">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-4">
                  <div>{Labels.LABEL_EMAIL_TITLE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputText
                    readOnly={isDisabled}
                    value={(messageChange && messageChange.emailTitle) || ""}
                    onChange={(e: any) => {
                      setMessageChange({
                        ...messageChange!,
                        emailTitle: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_EMAIL_TEXT}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Editor
                    headerTemplate={<div></div>}
                    readOnly={isDisabled}
                    modules={toolbar}
                    style={{ height: "320px" }}
                    value={messageChange && messageChange.emailText}
                    onTextChange={(e: any) => {
                      setMessageChange({
                        ...messageChange!,
                        emailText: e.htmlValue,
                        isEmail: e.htmlValue ? true : false,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_IS_EMAIL}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputSwitch
                    disabled={isDisabled}
                    onChange={(e) =>
                      setMessageChange({
                        ...messageChange!,
                        isEmail: e.target.value,
                      })
                    }
                    checked={messageChange && messageChange.isEmail}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.LABEL_NOTIFICATION}>
          <div className="scrollable-content">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-4">
                  <div>{Labels.LABEL_NOTIFICATION_TEXT}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputTextarea
                    readOnly={isDisabled}
                    value={(messageChange && messageChange.notificationText) || ""}
                    onChange={(e: any) => {
                      setMessageChange({
                        ...messageChange!,
                        notificationText: e.target.value,
                        isNotification: e.target.value ? true : false,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_IS_NOTIFICATION}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputSwitch
                    disabled={isDisabled}
                    onChange={(e) =>
                      setMessageChange({
                        ...messageChange!,
                        isNotification: e.target.value,
                      })
                    }
                    checked={messageChange && messageChange.isNotification}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel headerClassName={messageOperation === EntityOperation.CREATE && visibleRecipientTab ? "" : "display-none"} header={Labels.LABEL_MESSAGE_TO_USER_LIST}>
          <div className="scrollable-content">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-4">
                  <div>{Labels.LABEL_MESSAGE_TO_USER_LIST}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <MultiSelect
                    filter
                    placeholder={Labels.PLACEHOLDER_RECIPIENT}
                    optionLabel="name"
                    selectedItemsLabel={Labels.PLACEHOLDER_COUNT_SELECTED_RECIPIENT}
                    resetFilterOnHide
                    value={selectedUserList}
                    options={userList}
                    onChange={(e) => {
                      setSelectedUserList(e.value);
                      setMessageChange({
                        ...messageChange,
                        userIDList: e.value.map((value: any) => value.code),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
