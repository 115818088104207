import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState, useRef } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig, handleAxiosCallError } from "../../infrastructure/Utils";
import FaqCreateDto from "../../model/faq/FaqCreateDto";
import FaqReadDto from "../../model/faq/FaqReadDto";
import FaqUpdateDto from "../../model/faq/FaqUpdateDto";
import { AppContext } from "../../Store";
import CrudFaq from "./CrudFaq";

export default function CrudFaqList() {
  const { authData, showMessage, setShowBlockUI } = useContext(AppContext);

  const [faqList, setFaqList] = useState<Array<FaqReadDto>>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);

  const [entityOperation, setEntityOperation] = useState<any>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [faqToChange, setFaqToChange] = useState<FaqReadDto>();
  const [selectedFaq, setSelectedFaq] = useState<FaqReadDto | null>();
  const dialogRef = useRef<any>();
  const [first, setFirst] = useState(0);
  const [tableRows, setTableRows] = useState(10);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = (idSelectedFaq?: number) => {
    setShowBlockUI(true);
    const requestFaqList = axios.get(Endpoint.FAQ_LIST, axiosConfig(authData?.token));

    axios
      .all([requestFaqList])
      .then(
        axios.spread((responseFaqList: any) => {
          setFaqList(responseFaqList.data.data);
          setShowBlockUI(false);
          if (idSelectedFaq) {
            responseFaqList.data.data.forEach((faq: any) => {
              if (idSelectedFaq === faq.id) {
                setSelectedRow(faq);
                setSelectedFaq(faq);
              }
            });
          }
        })
      )
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
        setShowBlockUI(false);
      });
  };

  const createFaq = (faq: FaqCreateDto) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${Endpoint.FAQ_LIST}`, faq ? faq : {}, axiosConfig(authData.token))
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_CREATE_FAQ_SUCCESS, "");
          fetchData(response.data.data.id);
          resolve();
        })
        .catch((error: any) => {
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const updateFaq = (faq: FaqUpdateDto) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .put(`${Endpoint.FAQ_LIST}/${faq.id}`, faq, axiosConfig(authData.token))
        .then((response: any) => {
          setDisplayDialog(false);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_UPDATE_FAQ_SUCCESS, "");
          fetchData(response.data.data.id);
          resolve();
        })
        .catch((error: any) => {
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const deleteFaq = (faqID: number) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(`${Endpoint.FAQ_LIST}/${faqID}`, axiosConfig(authData.token))
        .then(() => {
          setDisplayDialog(false);
          setSelectedFaq(null);
          showMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_DELETE_FAQ_SUCCESS, "");
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_CREATE}
          icon="pi pi-save"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_UPDATE}
          icon="pi pi-inbox"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_DELETE}
          icon="pi pi-inbox"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </React.Fragment>
  );

  const rightContentsDialog = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_CANCEL} icon="pi pi-times" className="p-button-danger" onClick={() => onCancel()} />
    </React.Fragment>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  function openDialog(entityOperation: String, faqToChange?: FaqReadDto) {
    let u = undefined;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        u = faqToChange;
        break;
    }

    setEntityOperation(entityOperation);
    setFaqToChange(u);
    setDisplayDialog(true);
  }

  function closeDialog() {
    setDisplayDialog(false);
  }

  function onCancel() {
    closeDialog();
  }

  const questionAreaNameBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_QUESTION_AREA}</span>
        {rowData.questionArea.name}
      </React.Fragment>
    );
  };
  const titleBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_ARTICLE_TITLE}</span>
        {rowData.title}
      </React.Fragment>
    );
  };
  const displayOrderBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.LABEL_DISPLAY_ORDER}</span>
        {rowData.displayOrder}
      </React.Fragment>
    );
  };

  const dialogHeader = (entityOperation: String) => {
    const display = faqToChange ? faqToChange.title : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_CREATE_FAQ;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_DELETE_FAQ + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_UPDATE_FAQ + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_READ_FAQ + display;
      default:
        return "";
    }
  };

  const leftContents = () => (
    <React.Fragment>
      <Button
        className="toolbar-button"
        icon="pi pi-plus"
        onClick={() => {
          openDialog(EntityOperation.CREATE);
        }}
      />

      <Button
        className="toolbar-button"
        disabled={!selectedFaq}
        icon="pi pi-pencil"
        onClick={() => {
          selectedFaq && openDialog(EntityOperation.UPDATE, selectedFaq);
        }}
      />
      <Button
        className="toolbar-button"
        disabled={!selectedFaq}
        icon="pi pi-info-circle"
        onClick={() => {
          selectedFaq && openDialog(EntityOperation.READ, selectedFaq);
        }}
      />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button
        disabled={!selectedFaq}
        icon="pi pi-trash"
        onClick={() => {
          selectedFaq && openDialog(EntityOperation.DELETE, selectedFaq);
        }}
      />
    </React.Fragment>
  );

  return (
    <div className="layout-faq">
      <Panel header={Labels.MENU_FAQ_LIST}>
        <Toolbar left={leftContents} right={rightContents} />
        <div className="datatable-responsive">
          <DataTable
            value={faqList}
            paginator
            first={first}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            rows={tableRows}
            rowsPerPageOptions={[5, 10, 20]}
            onPage={(e) => {
              setTableRows(e.rows);
              setFirst(e.first);
            }}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedFaq(e.data)}
            emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
            className="p-datatable-responsive"
          >
            <Column className="column-left" field={"questionArea.name"} header={Labels.LABEL_QUESTION_AREA} filter filterMatchMode="contains" body={questionAreaNameBodyTemplate} />
            <Column className="column-left" field={"title"} header={Labels.LABEL_ARTICLE_TITLE} filter filterMatchMode="contains" body={titleBodyTemplate} />
            <Column className="column-left" field={"displayOrder"} header={Labels.LABEL_DISPLAY_ORDER} filter filterMatchMode="contains" sortable body={displayOrderBodyTemplate} />
          </DataTable>
        </div>
        <Dialog header={dialogHeader(entityOperation)} visible={displayDialog} onHide={closeDialog} style={{ width: "1000px" }} footer={dialogFooter()}>
          <CrudFaq onCreateFaq={createFaq} onUpdateFaq={updateFaq} onDeleteFaq={deleteFaq} faqOperation={entityOperation} faq={faqToChange} onCancel={onCancel} dialogRef={dialogRef} />
        </Dialog>
      </Panel>
    </div>
  );
}
