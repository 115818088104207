import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig } from "../../infrastructure/Utils";
import ActivityReadDto from "../../model/activity/ActivityReadDto";
import CommentActivityCreateDto from "../../model/comment/CommentActivityCreateDto";
import CommentEventCreateDto from "../../model/comment/CommentEventCreateDto";
import CommentReadDto from "../../model/comment/CommentReadDto";
import CommentUpdateDto from "../../model/comment/CommentUpdateDto";
import EventReadDto from "../../model/event/EventReadDto";
import { AppContext } from "../../Store";
import Comment from "./Comment";

interface commentProps {
  event?: EventReadDto;
  activity?: ActivityReadDto;
}

export default function CommentList(props: commentProps) {
  const { event, activity } = props;
  const [commentList, setCommentList] = useState<Array<CommentReadDto>>([]);
  const { authData, showMessage } = useContext(AppContext);
  const [comment, setComment] = useState("");
  const [commentEditId, setCommentEditId] = useState<number>();

  const fetchData = () => {
    axios
      .get(event ? `${Endpoint.EVENT}/eventList/${event.id}/commentList` : `${Endpoint.ACTIVITY}/activityList/${activity?.id}/commentList`, axiosConfig(authData.token))
      .then((response: any) => {
        setCommentList(response.data.data);
      })
      .catch((error) => {
        const messages = error.response.data.errors
          .map((err: any) => {
            return err.message;
          })
          .join(" ");
        showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_ERROR, messages);
      });
  };

  useEffectOnce(() => {
    fetchData();
  });

  const deleteComment = (id: number) => {
    axios
      .delete(`${Endpoint.COMMENT}/commentList/${id}`, axiosConfig(authData.token))
      .then((response: any) => {
        fetchData();
      })
      .catch((error) => {
        const messages = error.response.data.errors
          .map((err: any) => {
            return err.message;
          })
          .join(" ");
        showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_DELETE_ERROR, messages);
      });
  };

  const createOrEditComment = () => {
    if (comment !== "") {
      if (!commentEditId) {
        axios
          .post(
            event ? `${Endpoint.COMMENT}/commentList/event` : `${Endpoint.COMMENT}/commentList/activity`,
            event
              ? new CommentEventCreateDto({ id: authData.currentUser.id }, { id: event.id }, comment)
              : new CommentActivityCreateDto({ id: authData.currentUser.id }, { id: activity!.id }, comment),
            axiosConfig(authData.token)
          )
          .then((response: any) => {
            fetchData();
            setComment("");
          })
          .catch((error) => {
            const messages = error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ");
            showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_CREATE_ERROR, messages);
          });
      } else {
        axios
          .put(
            event ? `${Endpoint.COMMENT}/commentList/${event.id}` : `${Endpoint.COMMENT}/commentList/${activity?.id}`,
            new CommentUpdateDto(commentEditId, { id: authData.currentUser.id }, comment),
            axiosConfig(authData.token)
          )
          .then((response: any) => {
            fetchData();
            setComment("");
            setCommentEditId(undefined);
          })
          .catch((error) => {
            const messages = error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ");
            showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_UPDATE_ERROR, messages);
          });
      }
    }
  };

  const prepareEdit = (id: number, text: string) => {
    setComment(text);
    setCommentEditId(id);
  };

  return (
    <div className="layout-comment">
      {commentList.map((comment: CommentReadDto) => {
        return <Comment horizontalLine={true} comment={comment} event={event} activity={activity} onEditComment={prepareEdit} onDeleteComment={deleteComment} key={comment.id} />;
      })}
      {/* <div className="p-grid p-align-center">
        <div className="p-col-1 profile-picture">
          <img src={getImage(false, authData.currentUser.profilePicture, Images.profile)} alt="" width="100%" />
        </div>
        <div className="p-col-10 text-input">
          <InputText
            value={comment}
            onChange={(e: any) => {
              setComment(e.target.value);
            }}
            placeholder={Labels.PLACEHOLDER_POST_COMMENT}
          />
        </div>
        <div
          className="p-col-1 post-comment"
          onClick={() => {
            createOrEditComment();
          }}
        >
          {commentEditId ? Labels.LABEL_EDIT : Labels.LABEL_POST}
        </div>
      </div> */}
    </div>
  );
}
