import React from "react";
import { dateAndTime, getImage, showActivityDuration } from "../../infrastructure/Utils";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import TwoLineText from "../common/TwoLineText";
import ActivityReadDto from "../../model/activity/ActivityReadDto";
import Images from "../../infrastructure/Images";
import EnumActivitySport from "../../model/activity/EnumActivitySport";
interface Props {
  activity: ActivityReadDto;
}

export default function EventHeader(props: Props) {
  const { activity } = props;
  if (!activity) {
    return null;
  }
  return (
    <div>
      <div className="p-grid p-dir-row p-align-center layout-user">
        <div className="p-col-1 profile-picture-avatar">
          <img src={getImage(false, activity.user.profilePicture, Images.profile)} alt="" width="100%" />
        </div>
        <div className="p-col-11">
          <div className="p-grid p-dir-column p-nogutter p-align-center">
            <div className="p-col-12 text-left text-bold">{activity.user.chosenName}</div>
            <div className="p-col-12 text-left font-size-small">{dateAndTime(activity.startTime)}</div>
          </div>
        </div>
      </div>
      <div className="p-grid p-dir-row ">
        <div className="p-col-1" />
        <div className="text-bold p-col-11  color-red">{activity.description}</div>
      </div>
      <div className="p-grid p-dir-row p-nogutter">
        <div className="p-col-12">
          <div className="p-grid p-dir-row p-nogutter">
            <TwoLineText firstLine={Labels.LABEL_LENGTH_M} secondLine={activity.totalDistanceMeters ? String(activity.totalDistanceMeters) : "0"} leadingSeparator={false} />
            {activity.activitySport === EnumActivitySport.WALKING_PEDOMETER && <TwoLineText firstLine={Labels.LABEL_STEPS} secondLine={String(activity.totalSteps)} leadingSeparator={false} />}
            <TwoLineText firstLine={Labels.ACTIVITY_DURATION} secondLine={showActivityDuration(activity.totalTimeSeconds)} leadingSeparator={false} />
          </div>
        </div>
      </div>
    </div>
  );
}
