import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova/theme.css";
import React from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import BlockUI from "./BlockUI";
import Store from "./Store";

ReactDOM.render(
  <BrowserRouter>
    <Store>
      <BlockUI />
      <App />
    </Store>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
