import axios from "axios";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import React, { useContext, useEffect, useState } from "react";
import Endpoint from "../../infrastructure/Endpoint";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig } from "../../infrastructure/Utils";
import ActivityReadDto from "../../model/activity/ActivityReadDto";
import { AppContext } from "../../Store";
import DisplayActivity from "./DisplayActivity";

interface DisplayActivityListProps {
  userId: number;
}

export default function DisplayActivityList(props: DisplayActivityListProps) {
  const { userId } = props;
  const { authData, showMessage, setShowBlockUI } = useContext(AppContext);
  const [page, setPage] = useState(0);
  const [loadButtonVisible, setLoadButtonVisible] = useState(true);

  const [activityList, setActivityList] = useState([]);

  const fetchData = () => {
    setShowBlockUI(true);
    const requestActivityList = axios.get(`${Endpoint.USER}/userList/${userId}/activityList`, axiosConfig(authData.token, { size: 3, page: page }));

    axios
      .all([requestActivityList])
      .then(
        axios.spread((responseActivityList: any) => {
          if (responseActivityList.data.data.length < 3) {
            setLoadButtonVisible(false);
          }
          setActivityList(activityList.concat(responseActivityList.data.data));
          setShowBlockUI(false);
        })
      )
      .catch((error) => {
        setShowBlockUI(false);
        if (error.response.data) {
          const messages = error.response.data.errors
            .map((err: any) => {
              return err.message;
            })
            .join(" ");
          showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_ERROR, messages);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="p-grid p-fluid p-align-top p-justify-center layout-activity">
      <div className="p-col-12 panel-activity">
        <Panel header={Labels.TITLE_ACTIVITIES}>
          {activityList.map((activity: ActivityReadDto) => {
            return <DisplayActivity key={activity.id} activity={activity} onComment={() => {}} onShare={() => {}} />;
          })}
          {loadButtonVisible && (
            <Button
              icon="pi pi-plus"
              label={Labels.PLACEHOLDER_BUTTON_LOAD_MORE}
              onClick={() => {
                setPage(page + 1);
              }}
            />
          )}
        </Panel>
      </div>
    </div>
  );
}
