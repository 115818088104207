import React from "react";
import Endpoint from "../../infrastructure/Endpoint";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export default function AppFooter() {
  return (
    <div className="p-grid p-fluid p-justify-center layout-app-footer p-nogutter">
      <div className="p-xl-3 p-lg-6 p-md-6 p-md-12 p-grid p-dir-row p-align-center text-center">
        <div className="p-xl-3 p-lg-6 p-md-12 p-sm-12 text-center">
          <button className="p-link">
            <a href={Endpoint.OTVORI_PLAVI_KRUG}>
              <img src={Images.LOGO_ON_DARK} alt="#" className="header-home" />
            </a>
          </button>
        </div>
        <div className="p-xl-3 p-lg-6 p-md-12 p-sm-12 text-center">
          <button className="p-link">
            <a href={Endpoint.TWITTER_GRADOVI_MENJAJU_DIJABETES} target="_blank" rel="noopener noreferrer">
              <img src={Images.TWITTER} alt="#" className="header-twitter" />
            </a>
          </button>
        </div>
      </div>
      <div className="p-xl-3 p-lg-3 p-md-12 p-sm-12 text-center p-d-flex p-jc-center">
        <button className="p-link button-margin">
          <a href={Endpoint.GOOGLE_PLAY} target="_blank" rel="noopener noreferrer">
            <img src={Images.googlePlay} alt="#" className="header-twitter" />
          </a>
        </button>
        <button className="p-link button-margin">
          <a href={Endpoint.APP_STORE} target="_blank" rel="noopener noreferrer">
            <img src={Images.appStore} alt="#" className="header-twitter" />
          </a>
        </button>
      </div>

      <div className="p-xl-3 p-lg-6 p-md-6 p-sm-12 p-col-align-center text-center p-dir-col">
        <div className="p-col-12">
          <span className="text">{Labels.CONTACT}</span>
        </div>
        <div className="p-col-12">
          <button className="p-link">
            <a href="mailto:info@otvoriplavikrug.rs">
              <span className="text-link">{Labels.CONTACT_EMAIL}</span>
            </a>
          </button>
        </div>
      </div>
      <div className="top-border p-nogutter" />
      <div className="p-col-12 text-center p-nogutter">
        <button className="p-link">
          <span className="developed-by-text">{Labels.COPYRIGHT}</span>
          <a href="https://heliant.rs" target="_blank" rel="noopener noreferrer" className="link">
            <span className="text">{Labels.HELIANT}</span>
          </a>
        </button>
      </div>
    </div>
  );
}
