import axios from "axios";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { GMap } from "primereact/gmap";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { TabPanel, TabView } from "primereact/tabview";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useContext, useState, useImperativeHandle, createRef } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, CALENDAR_DATE_FORMAT, isFormDisabled, getImage, INITIAL_LATITUDE, INITIAL_LONGITUDE, getBase64Content, DATE_TIME_FORMAT_3, DATE_TIME_FORMAT } from "../../infrastructure/Utils";
import EventReadDto from "../../model/event/EventReadDto";
import { AppContext } from "../../Store";
import Images from "../../infrastructure/Images";
import ImageUploader from "react-images-upload";
import moment from "moment";
import EventCreateDto from "../../model/event/EventCreateDto";
import RegistrationList from "./RegistrationList";
import RegistrationEventReadDto from "../../model/event/RegistrationEventReadDto";

interface CrudEventProp {
  eventOperation: string;
  event?: EventReadDto;
  onCancel: Function;
  onCreateEvent: Function;
  onUpdateEvent: Function;
  onDeleteEvent: Function;
  dialogRef?: any;
}

export default function CrudEvent(prop: CrudEventProp) {
  const { eventOperation, event, onCreateEvent, onUpdateEvent, onDeleteEvent, dialogRef } = prop;

  const { authData } = useContext(AppContext);

  const [eventStatusList, setEventStatusList] = useState([]);
  const [eventDifficultyList, setEventDifficultyList] = useState([]);
  const [eventSportList, setEventSportList] = useState([]);
  const [eventChange, setEventChange] = useState<any>(event);
  const [overlays, setOverlays] = useState<any>([]);
  const isDisabled = isFormDisabled(eventOperation);
  const [isUpdateSummaryImage, setisUpdateSummaryImage] = useState(false);
  const [isUpdateHeaderImage, setisUpdateHeaderImage] = useState(false);
  const [isUpdateStoryShareImage, setIsUpdateStoryShareImage] = useState(false);
  const [displayRegistrationDialog, setDisplayRegistrationDialog] = useState(false);
  const [registrationList, setRegistrationList] = useState<Array<RegistrationEventReadDto>>([]);
  const [chosenName, setChosenName] = useState("");
  const mapRef = createRef<any>();

  let [messages, setMessages] = useState<any>("");

  useEffectOnce(() => {
    if (eventOperation !== EntityOperation.CREATE) {
      setEventChange({
        ...eventChange,
        eventSport: eventChange.eventSport.code,
        eventStatus: eventChange.eventStatus.code,
        eventDifficulty: eventChange.eventDifficulty.code,
      });
    }
    if (event?.startLatitude && event.startLongitude) {
      try {
        let startMarker = new google.maps.Marker({
          position: {
            lat: event.startLatitude,
            lng: event.startLongitude,
          },
          icon: Images.START_MARKER,
        });
        setOverlays([startMarker]);
      } catch (error) {
        showError(Labels.TITLE_MESSAGES_ERROR, "");
      }
    }
    const requestEventStatusList = axios.get(Endpoint.EVENT_STATUS_LIST, axiosConfig(authData.token));
    const requstEventDifficultyList = axios.get(Endpoint.EVENT_DIFFICULTY_LIST, axiosConfig(authData.token));
    const requstEventSportList = axios.get(Endpoint.EVENT_SPORT_LIST, axiosConfig(authData.token));

    axios
      .all([requestEventStatusList, requstEventDifficultyList, requstEventSportList])
      .then(
        axios.spread((responseEventStatusList, responseEventDifficultyList, responseEventSportList) => {
          const eventStatus = responseEventStatusList.data.map((eventStatus: any) => {
            return { label: eventStatus.name, value: eventStatus.code };
          });
          setEventStatusList(eventStatus);
          const eventDifficulty = responseEventDifficultyList.data.map((eventDifficulty: any) => {
            return { label: eventDifficulty.name, value: eventDifficulty.code };
          });
          setEventDifficultyList(eventDifficulty);
          const eventSport = responseEventSportList.data.map((eventSport: any) => {
            return { label: eventSport.name, value: eventSport.code };
          });
          setEventSportList(eventSport);
        })
      )
      .catch((error) => {
        showError(Labels.TITLE_MESSAGES_ERROR, error.response.data.message);
      });
  });

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      onCreateEvent(eventChange)
        .then(() => {})
        .catch((error: any) => {
          showError("", error);
        });
    },
    onUpdate: () => {
      onUpdateEvent(eventChange)
        .then(() => {})
        .catch((error: any) => {
          showError("", error);
        });
    },
    onDelete: () => {
      onDeleteEvent(eventChange?.id)
        .then(() => {})
        .catch((error: any) => {
          showError("", error);
        });
    },
    takeSnapshot: () => {
      takeSnapshot();
    },
  }));

  const takeSnapshot = () => {
    try {
      var newEvent: EventCreateDto = eventChange;
      if (newEvent.startLatitude && newEvent.startLongitude) {
        const requestMapSnapshot = axios.get(
          `https://maps.googleapis.com/maps/api/staticmap?size=390x250&zoom=15&markers=color:blue|${newEvent.startLatitude},${newEvent.startLongitude}&key=AIzaSyAfphPUe7nyvb0MQfYwT9zAQMSxm1tIQ0c`,
          { responseType: "blob" }
        );
        axios
          .all([requestMapSnapshot])
          .then(
            axios.spread((responseMapSnapshot) => {
              var reader = new FileReader();
              reader.readAsDataURL(responseMapSnapshot.data);
              reader.onload = function () {
                newEvent = { ...newEvent, startSnapshot: getBase64Content(reader.result?.toString()) };
                (eventOperation === EntityOperation.CREATE ? onCreateEvent(newEvent) : onUpdateEvent(newEvent))
                  .then(() => {})
                  .catch((error: any) => {
                    showError("", error);
                  });
              };
            })
          )
          .catch((e) => {});
      } else {
        (eventOperation === EntityOperation.CREATE ? onCreateEvent(newEvent) : onUpdateEvent(newEvent))
          .then(() => {})
          .catch((error: any) => {
            showError("", error);
          });
      }
    } catch (error) {}
  };

  const showError = (summary: string, detail: string) => {
    messages.replace({
      severity: "error",
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const showSucces = (summary: string, detail: string) => {
    messages.replace({
      severity: "success",
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const onMapClick = (event: any) => {
    if (EntityOperation.CREATE === eventOperation || EntityOperation.UPDATE === eventOperation) {
      let startMarker = new google.maps.Marker({
        position: {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
        icon: Images.START_MARKER,
      });
      setOverlays([startMarker]);
      setEventChange({
        ...eventChange!,
        startLatitude: event.latLng.lat(),
        startLongitude: event.latLng.lng(),
      });
    }
  };

  const onDrop = (picture: any) => {
    var reader = new FileReader();
    if (picture[0]) {
      reader.readAsDataURL(picture[0]);
      reader.onload = function () {
        setEventChange({ ...eventChange!, summaryImage: reader.result?.toString().substr(reader.result?.toString().indexOf(",") + 1) });
      };
      reader.onerror = function (errorr) {
        showError(Labels.MESSAGES_UPLOAD_IMAGE_ERROR, "");
      };
    }
    setisUpdateSummaryImage(true);
  };

  const onDropHeaderImage = (picture: any) => {
    var reader = new FileReader();
    if (picture[0]) {
      reader.readAsDataURL(picture[0]);
      reader.onload = function () {
        setEventChange({ ...eventChange!, headerImage: reader.result?.toString().substr(reader.result?.toString().indexOf(",") + 1) });
      };
      reader.onerror = function (errorr) {
        showError(Labels.TITLE_MESSAGES_ERROR, Labels.MESSAGES_UPLOAD_IMAGE_ERROR);
      };
    }
    setisUpdateHeaderImage(true);
  };

  const onDropStoryShareImage = (picture: any) => {
    var reader = new FileReader();
    if (picture[0]) {
      reader.readAsDataURL(picture[0]);
      reader.onload = function () {
        setEventChange({ ...eventChange!, storyShareImage: reader.result?.toString().substr(reader.result?.toString().indexOf(",") + 1) });
      };
      reader.onerror = function (errorr) {
        showError(Labels.TITLE_MESSAGES_ERROR, Labels.MESSAGES_UPLOAD_IMAGE_ERROR);
      };
    }
    setIsUpdateStoryShareImage(true);
  };

  const fetchRegistrationList = (eventId: number) => {
    const requstRegistrationList = axios.get(`${Endpoint.EVENT_LIST}/${eventId}/registrationList`, axiosConfig(authData.token));
    axios
      .all([requstRegistrationList])
      .then(
        axios.spread((responseRegistrationList) => {
          const registrationList = responseRegistrationList.data.data.map((registration: any) => {
            return {
              code: registration.code,
              chosenName: registration.user.chosenName,
              email: registration.user.email,
              insertTimestamp: moment(registration.insertTimestamp).format(DATE_TIME_FORMAT),
            };
          });
          setRegistrationList(registrationList);
        })
      )
      .catch((error) => {
        showError(Labels.TITLE_MESSAGES_ERROR, error.response.data.message);
      });
  };

  const createRegistration = (eventId: number) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Endpoint.EVENT_LIST}/${eventId}/registerWithChosenName`, chosenName, axiosConfig(authData.token))
        .then((response: any) => {
          setDisplayRegistrationDialog(false);
          setChosenName("");
          resolve(response);
        })
        .catch((error: any) => {
          reject(
            error.response.data.errors
              .map((err: any) => {
                return err.message;
              })
              .join(" ")
          );
        });
    });
  };

  return (
    <div className=" layout-event layout-common">
      <div className="p-col-12 padding-0">
        <Messages
          ref={(el: any) => {
            setMessages(el);
          }}
        ></Messages>
      </div>
      <TabView>
        <TabPanel header={Labels.LABEL_CRUD_EVENT_DATA}>
          <div className="scrollable-content">
            <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-4">
                  <div>{Labels.LABEL_EVENT_SPORT}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Dropdown
                    disabled={isDisabled}
                    value={eventChange && eventChange.eventSport}
                    options={eventSportList}
                    onChange={(e) => {
                      setEventChange({
                        ...eventChange!,
                        eventSport: e.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_EVENT_STATUS}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Dropdown
                    disabled={isDisabled}
                    value={eventChange && eventChange.eventStatus}
                    options={eventStatusList}
                    onChange={(e) => {
                      setEventChange({
                        ...eventChange!,
                        eventStatus: e.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_EVENT_DIFFICULTY}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Dropdown
                    disabled={isDisabled}
                    value={eventChange && eventChange.eventDifficulty}
                    options={eventDifficultyList}
                    onChange={(e) => {
                      setEventChange({
                        ...eventChange!,
                        eventDifficulty: e.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_TITLE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputText
                    disabled={isDisabled}
                    value={eventChange && eventChange.title}
                    onChange={(e: any) => {
                      setEventChange({
                        ...eventChange!,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_START_TIME}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Calendar
                    autoZIndex
                    disabled={isDisabled}
                    value={eventChange && eventChange.startTime ? new Date(eventChange.startTime) : undefined}
                    onChange={(e: any) => {
                      setEventChange({
                        ...eventChange!,
                        startTime: moment(e.value.toString()).format(DATE_TIME_FORMAT_3),
                      });
                    }}
                    showTime={true}
                    hourFormat="24"
                    dateFormat={CALENDAR_DATE_FORMAT}
                    showIcon={true}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_LENGTH_M}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputText
                    disabled={isDisabled}
                    className="input-number"
                    type="number"
                    keyfilter="pint"
                    value={eventChange && eventChange.totalDistanceMeters}
                    onChange={(e: any) => {
                      setEventChange({
                        ...eventChange!,
                        totalDistanceMeters: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_MAX_PERSONS}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputText
                    disabled={isDisabled}
                    className="input-number"
                    type="number"
                    keyfilter="pint"
                    value={eventChange && eventChange.maxPersons}
                    onChange={(e: any) => {
                      setEventChange({
                        ...eventChange!,
                        maxPersons: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_PUBLIC_TRANSPORT_LINES}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputText
                    disabled={isDisabled}
                    value={eventChange && eventChange.publicTransportLines}
                    onChange={(e: any) => {
                      setEventChange({
                        ...eventChange!,
                        publicTransportLines: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_SUMMARY_IMAGE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  {eventOperation !== EntityOperation.CREATE && !isUpdateSummaryImage && event?.summaryImage && <img src={getImage(false, event?.summaryImage, null)} className="image-position" />}
                  {(eventOperation === EntityOperation.UPDATE || eventOperation === EntityOperation.CREATE) && (
                    <ImageUploader
                      className="uploader-position"
                      singleImage={true}
                      withLabel={false}
                      withPreview={true}
                      withIcon={false}
                      buttonText={Labels.MESSAGES_CHOOSE_IMAGE}
                      buttonClassName="upload-button"
                      onChange={onDrop}
                      imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                      maxFileSize={65500}
                      fileSizeError={Labels.MESSAGES_FILE_SIZE_64KB_ERROR}
                    />
                  )}
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_WEB_SITE_IMAGE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  {eventOperation !== EntityOperation.CREATE && !isUpdateHeaderImage && event?.headerImage && <img src={getImage(false, event?.headerImage, null)} className="image-position" />}
                  {(eventOperation === EntityOperation.UPDATE || eventOperation === EntityOperation.CREATE) && (
                    <ImageUploader
                      className="uploader-position"
                      singleImage={true}
                      withLabel={false}
                      withPreview={true}
                      withIcon={false}
                      buttonText={Labels.MESSAGES_CHOOSE_IMAGE}
                      buttonClassName="upload-button"
                      onChange={onDropHeaderImage}
                      imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                      maxFileSize={409600}
                      fileSizeError={Labels.MESSAGES_FILE_SIZE_400KB_ERROR}
                    />
                  )}
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_STORY_SHATE_IMAGE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  {eventOperation !== EntityOperation.CREATE && !isUpdateStoryShareImage && event?.storyShareImage && (
                    <img src={getImage(false, event?.storyShareImage, null)} className="image-position" />
                  )}
                  {(eventOperation === EntityOperation.UPDATE || eventOperation === EntityOperation.CREATE) && (
                    <ImageUploader
                      className="uploader-position"
                      singleImage={true}
                      withLabel={false}
                      withPreview={true}
                      withIcon={false}
                      buttonText={Labels.MESSAGES_CHOOSE_IMAGE}
                      buttonClassName="upload-button"
                      onChange={onDropStoryShareImage}
                      imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                      maxFileSize={409600}
                      fileSizeError={Labels.MESSAGES_FILE_SIZE_400KB_ERROR}
                    />
                  )}
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_DESCRIPTION}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputTextarea
                    disabled={isDisabled}
                    value={eventChange && eventChange.description}
                    onChange={(e: any) => {
                      setEventChange({
                        ...eventChange!,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_NOTE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputTextarea
                    disabled={isDisabled}
                    value={eventChange && eventChange.note}
                    onChange={(e: any) => {
                      setEventChange({
                        ...eventChange!,
                        note: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.LABEL_EVENT_MAP}>
          <div ref={mapRef}>
            <GMap
              overlays={overlays}
              options={{
                center: event?.startLatitude && event.startLongitude ? { lat: event.startLatitude, lng: event.startLongitude } : { lat: INITIAL_LATITUDE, lng: INITIAL_LONGITUDE },
                zoom: 14,
              }}
              className="gmap"
              onMapClick={onMapClick}
            />
          </div>
        </TabPanel>
        <TabPanel header={Labels.LABEL_REGISTERED_USERS} disabled={eventOperation === EntityOperation.CREATE}>
          <RegistrationList
            displayRegistrationDialog={displayRegistrationDialog}
            setDisplayRegistrationDialog={setDisplayRegistrationDialog}
            fetchRegistrationList={fetchRegistrationList}
            registrationList={registrationList}
            eventId={event?.id}
            eventOperation={eventOperation}
            createRegistration={createRegistration}
            chosenName={chosenName}
            setChosenName={setChosenName}
            showSucces={showSucces}
          />
        </TabPanel>
      </TabView>
    </div>
  );
}
