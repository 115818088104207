import React from "react";
import Endpoint from "../../infrastructure/Endpoint";
import Images from "../../infrastructure/Images";

export default function AppHeader() {
  return (
    <div className="p-grid layout-app-header p-justify-center">
      <div className="header-top p-grid">
        <div className="buttonArea p-xl-3 p-lg-3 p-md-6 p-sm-12">
          <button className="p-link">
            <a href={Endpoint.PARTNER_GRADOVI_MENJAJU_DIJABETES} target="_blank" rel="noopener noreferrer">
              <img src={Images.PARTNER_GRADOVI_MENJAJU_DIJABETES} alt="#" className="header-logo" />
            </a>
          </button>
        </div>
        <div className="row">
          <h1 className="header-text mt-2">otvori plavi krug</h1>
        </div>
        <div className="buttonArea p-xl-3 p-lg-3 p-md-6 p-sm-12">
          <button className="p-link">
            <a href={Endpoint.OTVORI_PLAVI_KRUG}>
              <img src={Images.LOGO_ON_WHITE} alt="#" className="header-home" />
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}
