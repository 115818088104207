const apiBase = (window as any).REACT_APP_BACKEND_API_BASE;
const url = apiBase ? apiBase : "http://" + window.location.hostname + ":8080/opk";
const api = url + "/api";

const Endpoint = {
  AUTHENTICATE: url + "/web/authenticate",
  FUTURE_EVENT_LIST: api + "/event/eventList/active",
  EVENT_LIST: api + "/event/eventList",
  ACTIVE_MUNICIPALITY_LIST: api + "/codetable/activeMunicipalityList",
  EVENT_SPORT_LIST: api + "/codetable/eventSportList",
  EVENT_STATUS_LIST: api + "/codetable/eventStatusList",
  EVENT_DIFFICULTY_LIST: api + "/codetable/eventDifficultyList",
  FINISHED_EVENT_LIST: api + "/event/eventList/finished",
  FINISHED_EVENT_LIST_FOR_PERIOD: api + "/user/finishedEventListForPeriod",
  LAST_FINISHED_EVENT: api + "/user/lastFinishedEvent",
  SPONSOR_ORGANIZATION_LIST: api + "/codetable/sponsorOrganizationList",
  TOS: "/uploads/ToS.pdf",
  PRIVACY_POLICY: "/uploads/PrivacyPolicy.pdf",
  INSTAGRAM: "https://www.instagram.com/",
  FACEBOOK: "https://www.facebook.com/",
  REGISTRATION_PAGE: "/registration",
  LOGIN: "/loginPage",
  REGISTRATION: url + "/register",
  KUDOS: api + "/kudos",
  EVENT_REGISTRATION: api + "/event/registration/register",
  EVENT_REGISTRATION_CANCEL: api + "/event/registration/cancel",
  EVENT_COMMENT: api + "/event/comment",
  PROGRESS: api + "/user/progress",
  STATISTICS: api + "/user/statistics",
  USER: api + "/user",
  EVENT: api + "/event",
  COMMENT: api + "/comment",
  ACTIVITY: api + "/activity",
  ARTICLE_LIST: api + "/article/articleList",
  ACTIVE_ARTICLE_AREA_LIST: api + "/codetable/activeArticleAreaList",
  ARTICLE_TYPE_LIST: api + "/codetable/articleTypeList",
  ACTIVE_QUESTION_AREA_LIST: api + "/codetable/activeQuestionAreaList",
  PARTNER_GRADOVI_MENJAJU_DIJABETES: "https://www.citieschangingdiabetes.com/",
  TWITTER_GRADOVI_MENJAJU_DIJABETES: "https://twitter.com/citiesdiabetes",
  OTVORI_PLAVI_KRUG: "https://www.otvoriplavikrug.rs",
  REGISTRATION_STATUS: url + "/register/complete",
  REGISTRATION_RESEND: url + "/register/resend",
  RESET_PASSWORD: url + "/resetPassword",
  FAQ_LIST: api + "/faq/faqList",
  RANG_LIST: api + "/statistics/rankListTotalDistanceMetersForPeriod",
  STATISTICS_RANG_LIST: api + "/statistics/statisticsRankingsListForPeriod",
  GOOGLE_PLAY: "https://play.google.com/store/apps/details?id=rs.heliant.mobile.opk",
  APP_STORE: "https://apps.apple.com/us/app/otvori-plavi-krug/id1538660838#?platform=iphone",
  MESSAGE_LIST: api + "/message/messageList",
  USER_LIST: api + "/user/userList",
  ACTIVITY_RANGE: api + "/activity/activityRange",
};

export default Endpoint;
