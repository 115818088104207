import Microlink from "@microlink/react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext, useState, useImperativeHandle, useEffect } from "react";
import ImageUploader from "react-images-upload";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, getImage, isFormDisabled, toolbar } from "../../infrastructure/Utils";
import ArticleReadDto from "../../model/article/ArticleReadDto";
import EnumArticleType from "../../model/article/EnumArticleType";
import { AppContext } from "../../Store";
import { Checkbox } from "primereact/checkbox";
import ReactHtmlParser from "react-html-parser";
import EntityOperation from "../../infrastructure/EnumEntityOperation";

interface CrudArticleProp {
  articleOperation: string;
  article?: ArticleReadDto;
  onCancel: Function;
  onCreateArticle: Function;
  onUpdateArticle: Function;
  onDeleteArticle: Function;
  dialogRef?: any;
  setIndex: Function;
  index: number;
}

export default function CrudArticle(prop: CrudArticleProp) {
  const { articleOperation, article, onCancel, onCreateArticle, onUpdateArticle, onDeleteArticle, dialogRef, setIndex, index } = prop;

  const { authData } = useContext(AppContext);

  const [isDisabled] = useState(isFormDisabled(articleOperation));
  const [articleAreaList, setArticleAreaList] = useState([]);
  const [articleTypeList, setArticleTypeList] = useState([]);

  const [articleChange, setArticleChange] = useState<ArticleReadDto | undefined>(article);
  const [microlinkUrl, setMicrolinkUrl] = useState(article?.body);
  const [tabViewActiveIndex, setTabViewActiveIndex] = useState(0);
  const [selectedArticleType, setSelectedArticleType] = useState("");

  let [messages, setMessages] = useState<any>("");

  useEffectOnce(() => {
    const requestActiveArticleAreaList = axios.get(Endpoint.ACTIVE_ARTICLE_AREA_LIST, axiosConfig(authData.token));
    const requestArticleTypeList = axios.get(Endpoint.ARTICLE_TYPE_LIST, axiosConfig(authData.token));

    if (article) {
      axios
        .get(Endpoint.ARTICLE_LIST + `/${article?.id}/v2`, axiosConfig(authData.token))
        .then((responseArticleFull) => {
          setArticleChange(responseArticleFull.data.data);
        })
        .catch((error) => {
          showError(Labels.TITLE_MESSAGES_ERROR, error.response.data.message);
        });
    }

    axios
      .all([requestActiveArticleAreaList, requestArticleTypeList])
      .then(
        axios.spread((responseActiveArticleAreaList, responseArticleTypeList) => {
          setArticleAreaList(responseActiveArticleAreaList.data);

          const articleType = responseArticleTypeList.data.map((articleType: any) => {
            return { label: articleType.name, value: articleType.code };
          });
          setArticleTypeList(articleType);
        })
      )
      .catch((error) => {
        showError(Labels.TITLE_MESSAGES_ERROR, error.response.data.message);
      });
  });

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      onCreateArticle(articleChange)
        .then(() => {})
        .catch((error: any) => {
          showError(error, "");
        });
    },
    onUpdate: () => {
      onUpdateArticle(articleChange)
        .then(() => {})
        .catch((error: any) => {
          showError(error, "");
        });
    },
    onDelete: () => {
      onDeleteArticle(articleChange?.id)
        .then(() => {})
        .catch((error: any) => {
          showError(error, "");
        });
    },
  }));

  useEffect(() => {
    if (article?.articleType !== articleChange?.articleType) {
      setArticleChange({ ...articleChange!, body: "" });
    }
  }, [selectedArticleType]);

  const showError = (summary: string, detail: string) => {
    messages.replace({
      severity: "error",
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const onDrop = (picture: any) => {
    var reader = new FileReader();
    if (picture[0]) {
      reader.readAsDataURL(picture[0]);
      reader.onload = function () {
        setArticleChange({ ...articleChange!, summaryImage: reader.result?.toString().substr(reader.result?.toString().indexOf(",") + 1) });
      };
      reader.onerror = function (errorr) {
        showError(Labels.MESSAGES_UPLOAD_IMAGE_ERROR, "");
      };
    } else {
      setArticleChange({ ...articleChange!, summaryImage: undefined });
    }
  };

  const onDropHeaderImage = (picture: any) => {
    var reader = new FileReader();
    if (picture[0]) {
      reader.readAsDataURL(picture[0]);
      reader.onload = function () {
        setArticleChange({ ...articleChange!, headerImage: reader.result?.toString().substr(reader.result?.toString().indexOf(",") + 1) });
      };
      reader.onerror = function (errorr) {
        showError(Labels.TITLE_MESSAGES_ERROR, Labels.MESSAGES_UPLOAD_IMAGE_ERROR);
      };
    } else {
      setArticleChange({ ...articleChange!, headerImage: undefined });
    }
  };

  const onDropStoryShareImage = (picture: any) => {
    var reader = new FileReader();
    if (picture[0]) {
      reader.readAsDataURL(picture[0]);
      reader.onload = function () {
        setArticleChange({ ...articleChange!, storyShareImage: reader.result?.toString().substr(reader.result?.toString().indexOf(",") + 1) });
      };
      reader.onerror = function (errorr) {
        showError(Labels.TITLE_MESSAGES_ERROR, Labels.MESSAGES_UPLOAD_IMAGE_ERROR);
      };
    } else {
      setArticleChange({ ...articleChange!, headerImage: undefined });
    }
  };

  return (
    <div className="layout-article layout-common">
      <div className="p-col-12 padding-0">
        <Messages
          ref={(el: any) => {
            setMessages(el);
          }}
        ></Messages>
      </div>
      <TabView
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.TAB_ARTICLE_SUMMARY}>
          <div className="scrollable-content">
            <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-4">
                  <div>{Labels.LABEL_ARTICLE_AREA}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Dropdown
                    disabled={isDisabled}
                    dataKey="id"
                    value={articleChange?.articleArea ?? ""}
                    options={articleAreaList}
                    onChange={(e) => {
                      setArticleChange({
                        ...articleChange!,
                        articleArea: e.value,
                      });
                    }}
                    optionLabel="name"
                    filter={true}
                    filterBy="name"
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_ARTICLE_TITLE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputText
                    disabled={isDisabled}
                    value={articleChange?.title ?? ""}
                    onChange={(e: any) => {
                      setArticleChange({
                        ...articleChange!,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_ARTICLE_SUMMARY}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputText
                    disabled={isDisabled}
                    value={articleChange?.summary ?? ""}
                    onChange={(e: any) => {
                      setArticleChange({
                        ...articleChange!,
                        summary: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_ARTICLE_TYPE}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Dropdown
                    disabled={isDisabled}
                    value={articleChange?.articleType ?? ""}
                    options={articleTypeList}
                    onChange={(e) => {
                      setArticleChange({
                        ...articleChange!,
                        articleType: e.value,
                      });
                      setSelectedArticleType(e.value);
                    }}
                  />
                </div>
                <div className="p-col-4">
                  {(articleChange?.summaryImage || articleOperation === EntityOperation.CREATE || articleOperation === EntityOperation.UPDATE) && <div>{Labels.LABEL_ARTICLE_SUMMARY_IMAGE}</div>}
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  {(articleOperation === EntityOperation.READ || articleOperation === EntityOperation.DELETE) && articleChange?.summaryImage && (
                    <img src={getImage(false, articleChange?.summaryImage, null)} className="image-position-left" />
                  )}
                  {articleOperation === EntityOperation.UPDATE && articleChange?.summaryImage && <img src={getImage(false, articleChange?.summaryImage, null)} className="image-position" />}
                  {(articleOperation === EntityOperation.UPDATE || articleOperation === EntityOperation.CREATE) && (
                    <ImageUploader
                      className="uploader-position"
                      singleImage={true}
                      withLabel={false}
                      withPreview={true}
                      withIcon={false}
                      buttonText={Labels.MESSAGES_CHOOSE_IMAGE}
                      buttonClassName="upload-button"
                      onChange={onDrop}
                      imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                      maxFileSize={5242880}
                      fileSizeError={Labels.MESSAGES_FILE_SIZE_5MB_ERROR}
                    />
                  )}
                </div>
                <div className="p-col-4">
                  {(articleChange?.headerImage || articleOperation === EntityOperation.CREATE || articleOperation === EntityOperation.UPDATE) && <div>{Labels.LABEL_WEB_SITE_IMAGE}</div>}
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  {(articleOperation === EntityOperation.READ || articleOperation === EntityOperation.DELETE) && articleChange?.headerImage && (
                    <img src={getImage(false, articleChange?.headerImage, null)} className="image-position-left" />
                  )}
                  {articleOperation === EntityOperation.UPDATE && articleChange?.headerImage && <img src={getImage(false, articleChange?.headerImage, null)} className="image-position" />}
                  {(articleOperation === EntityOperation.UPDATE || articleOperation === EntityOperation.CREATE) && (
                    <ImageUploader
                      className="uploader-position"
                      singleImage={true}
                      withLabel={false}
                      withPreview={true}
                      withIcon={false}
                      buttonText={Labels.MESSAGES_CHOOSE_IMAGE}
                      buttonClassName="upload-button"
                      onChange={onDropHeaderImage}
                      imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                      maxFileSize={5242880}
                      fileSizeError={Labels.MESSAGES_FILE_SIZE_5MB_ERROR}
                    />
                  )}
                </div>
                <div className="p-col-4">
                  {(articleChange?.storyShareImage || articleOperation === EntityOperation.CREATE || articleOperation === EntityOperation.UPDATE) && <div>{Labels.LABEL_STORY_SHATE_IMAGE}</div>}
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  {(articleOperation === EntityOperation.READ || articleOperation === EntityOperation.DELETE) && articleChange?.storyShareImage && (
                    <img src={getImage(false, articleChange?.storyShareImage, null)} className="image-position-left" />
                  )}
                  {articleOperation === EntityOperation.UPDATE && articleChange?.storyShareImage && <img src={getImage(false, articleChange?.storyShareImage, null)} className="image-position" />}
                  {(articleOperation === EntityOperation.UPDATE || articleOperation === EntityOperation.CREATE) && (
                    <ImageUploader
                      className="uploader-position"
                      singleImage={true}
                      withLabel={false}
                      withPreview={true}
                      withIcon={false}
                      buttonText={Labels.MESSAGES_CHOOSE_IMAGE}
                      buttonClassName="upload-button"
                      onChange={onDropStoryShareImage}
                      imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                      maxFileSize={409600}
                      fileSizeError={Labels.MESSAGES_FILE_SIZE_400KB_ERROR}
                    />
                  )}
                </div>
                <div className="p-col-4">
                  <div>{Labels.LABEL_ARTICLE_ON_CAROUSEL}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <Checkbox
                    disabled={isDisabled}
                    onChange={(e) => {
                      setArticleChange({ ...articleChange!, onCarousel: e.checked });
                    }}
                    checked={articleChange && articleChange.onCarousel}
                  ></Checkbox>
                </div>

                <div className="p-col-4">
                  <div>{Labels.LABEL_TABLEAU_URL}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputText
                    disabled={isDisabled}
                    value={articleChange?.tableauUrl ?? ""}
                    onChange={(e: any) => {
                      console.log(e.target.value);
                      setArticleChange({
                        ...articleChange!,
                        tableauUrl: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.TAB_ARTICLE_CONTENT} disabled={!articleChange?.articleType}>
          {articleChange?.articleType === EnumArticleType.PLAIN_TEXT && (
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-6 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-12 p-fluid margin-top-bottom-5">
                  <InputTextarea
                    rows={20}
                    cols={120}
                    disabled={isDisabled}
                    value={articleChange && articleChange.body}
                    onChange={(e: any) => {
                      setArticleChange({
                        ...articleChange!,
                        body: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {articleChange?.articleType === EnumArticleType.HTML && (
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-6 p-sm-12 tab-view">
              <div className="p-grid p-align-center p-nogutter">
                <div className="p-col-12 p-fluid margin-top-bottom-5">
                  <Editor
                    headerTemplate={<div></div>}
                    modules={toolbar}
                    style={{ height: "320px" }}
                    value={articleChange && articleChange.body}
                    onTextChange={(e: any) => {
                      setArticleChange({
                        ...articleChange!,
                        body: e.htmlValue,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {articleChange?.articleType === EnumArticleType.URL && (
            <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
              <div className="p-grid p-align-center ">
                <div className="p-col-2">
                  <div>{Labels.LABEL_ARTICLE_URL}</div>
                </div>
                <div className="p-col-8 p-fluid margin-top-bottom-5">
                  <InputText
                    disabled={isDisabled}
                    value={articleChange && articleChange.body}
                    onChange={(e: any) => {
                      setArticleChange({
                        ...articleChange!,
                        body: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-col-2">
                  <Button
                    label={Labels.BUTTON_PREVIEW}
                    icon="pi pi-save"
                    className="p-button-success"
                    onClick={() => {
                      setMicrolinkUrl(articleChange?.body ? articleChange?.body : "");
                    }}
                  />
                </div>
                <div className="p-col-12">{microlinkUrl && microlinkUrl.length > 0 && microlinkUrl.startsWith("http") && <Microlink url={microlinkUrl.toString()} size="large" />}</div>
              </div>
            </div>
          )}
          {articleChange?.articleType === EnumArticleType.VIDEO && (
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12 tab-view">
              <div className="p-grid p-align-center ">
                <div className="p-col-12 p-d-flex align-items-center">
                  <div className="p-fluid pr-0">{Labels.LABEL_ARTICLE_VIDEO}</div>
                  <div className="p-col-8 p-fluid pl-0">
                    <InputText
                      disabled={isDisabled}
                      value={articleChange && articleChange.youtubeCode}
                      onChange={(e: any) => {
                        setArticleChange({
                          ...articleChange!,
                          youtubeCode: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </TabPanel>
        <TabPanel headerClassName={articleChange?.articleType !== EnumArticleType.VIDEO ? "" : "display-none"} header={Labels.TAB_ARTICLE_PREVIEW_SUMMARY}>
          <div className="scrollable-content">
            <div className="card mt-3 mb-3">
              {articleChange?.summaryImage && <img src={getImage(false, articleChange.summaryImage, null)} className="card-img-top" />}
              <div className="card-body">
                <h5 className="card-title text-left">{articleChange?.title}</h5>
                <p className="text-left">{articleChange?.summary}</p>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel headerClassName={articleChange?.articleType !== EnumArticleType.VIDEO ? "" : "display-none"} header={Labels.TAB_ARTICLE_PREVIEW_CONTENT}>
          <div className="scrollable-content">
            <div>
              <div>
                <div id="home" className="slider">
                  <img className="d-block w-100" src={articleChange?.headerImage ? getImage(false, articleChange?.headerImage, null) : null} alt="header" />
                </div>
                <div id="article-ID" className="content-preview" style={{ overflow: "hidden" }}>
                  <p className="mt-3 mb-5 content-title">{articleChange?.title}</p>
                  {articleChange?.body && articleChange?.articleType === EnumArticleType.HTML && <div className="reactHtmlParserImg">{ReactHtmlParser(articleChange.body)}</div>}
                  {articleChange?.articleType === EnumArticleType.URL && <a href={articleChange.body}>{articleChange.body}</a>}
                  {articleChange?.articleType === EnumArticleType.PLAIN_TEXT && <div>{articleChange.body}</div>}
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
