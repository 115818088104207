import moment from "moment";
import EntityOperation from "./EnumEntityOperation";
import Labels from "./Labels_sr_Latn_RS";
import MessageType from "./MessageType";

const isPublicRoute = (route: string) => {
  return route.startsWith("/public");
};

const stringChecker = (s: string | undefined | null) => {
  return s ? s : "";
};

const axiosConfig = (token: string, params?: Object) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

const isFormDisabled = (operation: string) => {
  let returnValue = true;

  if (!operation) return true;

  switch (operation) {
    case EntityOperation.CREATE:
    case EntityOperation.UPDATE:
      returnValue = false;
      break;
    case EntityOperation.READ:
    case EntityOperation.DELETE:
      returnValue = true;
      break;
    default:
      returnValue = true;
      break;
  }

  return returnValue;
};

const dateAndTime = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_TIME_FORMAT);
  } else {
    return "Lose zadat datum";
  }
};

const date = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_FORMAT);
  } else {
    return "Lose zadat datum";
  }
};

const time = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format("h:mm");
  } else {
    return "Lose zadat datum";
  }
};

const dateTimeBefore = (date: any, dateNow: any) => {
  const now = moment(dateNow);
  const durationAsMinutes = moment.duration(now.diff(date)).asMinutes();
  if (durationAsMinutes < 60) {
    return Labels.LABEL_BEFORE + " " + Math.floor(durationAsMinutes + 1) + " " + Labels.LABEL_MINUTES;
  } else if (durationAsMinutes < 24 * 60) {
    return Labels.LABEL_BEFORE + " " + Math.floor(durationAsMinutes / 60) + " " + Labels.LABEL_HOURS;
  } else {
    return moment(date).format(DATE_TIME_FORMAT);
  }
};

const imageExtention = (base64: string) => {
  if (base64) {
    switch (base64.charAt(0)) {
      case "/":
        return "jpg";
      case "i":
        return "png";
      case "R":
        return "gif";
      case "U":
        return "webp";
    }
  }
};

const getImage = (needUri: boolean, source: string, replaceImage: any) => {
  return source ? (needUri ? { uri: `data:image/${imageExtention(source)};base64,${source}` } : `data:image/${imageExtention(source)};base64,${source}`) : replaceImage;
};

const showActivityDuration = (seconds: number) => {
  return seconds ? new Date(seconds * 1000).toISOString().substr(11, 8) : "00:00:00";
};

const handleAxiosCallSuccess = (showMessage: Function, response: any) => {
  if (response && response.data && response.data.data && response.data.data.successes) {
    showMessage(
      MessageType.SUCCESS,
      undefined,
      response.data.data.successes.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.SUCCESS, undefined, response.toString());
  }
};

const handleAxiosCallError = (showMessage: Function, error: any) => {
  if (error && error.response && error.response.data && error.response.data.errors) {
    showMessage(
      MessageType.ERROR,
      undefined,
      error.response.data.errors.map((e: any) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.ERROR, undefined, error.toString());
  }
};

const meterToKilometer = (value: number, decimal: number) => {
  return Number((value / 1000).toFixed(decimal));
};

const getBase64Content = (readerResult: String | undefined) => {
  return readerResult ? readerResult.substr(readerResult.indexOf(",") + 1) : undefined;
};

const getMonthName = (monthNumber: number) => {
  switch (monthNumber) {
    case 1:
      return Labels.LABEL_JANUARY;
    case 2:
      return Labels.LABEL_FEBRUARY;
    case 3:
      return Labels.LABEL_MARCH;
    case 4:
      return Labels.LABEL_APRIL;
    case 5:
      return Labels.LABEL_MAY;
    case 6:
      return Labels.LABEL_JUNE;
    case 7:
      return Labels.LABEL_JULY;
    case 8:
      return Labels.LABEL_AUGUST;
    case 9:
      return Labels.LABEL_SEPTEMBER;
    case 10:
      return Labels.LABEL_OCTOBER;
    case 11:
      return Labels.LABEL_NOVEMBER;
    case 12:
      return Labels.LABEL_DECEMBER;
    default:
      return "";
  }
};

const DATE_TIME_FORMAT = "DD.MM.YYYY. HH:mm";
const DATE_TIME_FORMAT_FULL = "DD.MM.YYYY. HH:mm:ss";
const DATE_FORMAT = "DD.MM.YYYY.";
const DATE_FORMAT2 = "YYYY-MM-DD";
const CALENDAR_DATE_FORMAT = "dd.mm.yy.";
const DATE_TIME_FORMAT_2 = "YYYY-MM-DD HH:mm:ss";
const DATE_TIME_FORMAT_3 = "YYYY-MM-DDTHH:mm:ss";
const INITIAL_LATITUDE = 44.786568;
const INITIAL_LONGITUDE = 20.448922;

const quillColors = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
  "#2a918b",
];

const toolbar = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ color: quillColors }, { background: quillColors }], // dropdown with defaults from theme
    [{ align: [] }],
    ["link", "image"],
  ],
};

const validateStringEmpty = (str: string | undefined) => !str || str.trim() === "";

const tooltipOptionsTop: any = { position: "top", hideDelay: 50 };
const tooltipOptionsBottom: any = { position: "bottom" };
const tooltipOptionsRight: any = { position: "right" };

export {
  getBase64Content,
  isPublicRoute,
  getImage,
  showActivityDuration,
  imageExtention,
  dateTimeBefore,
  stringChecker,
  isFormDisabled,
  dateAndTime,
  date,
  time,
  axiosConfig,
  getMonthName,
  CALENDAR_DATE_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT_FULL,
  DATE_FORMAT2,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_2,
  DATE_TIME_FORMAT_3,
  INITIAL_LATITUDE,
  INITIAL_LONGITUDE,
  handleAxiosCallError,
  handleAxiosCallSuccess,
  meterToKilometer,
  toolbar,
  validateStringEmpty,
  tooltipOptionsTop,
  tooltipOptionsBottom,
  tooltipOptionsRight,
};
