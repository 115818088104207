import axios from "axios";
import { Panel } from "primereact/panel";
import React, { useContext, useState } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig } from "../../infrastructure/Utils";
import CtOrganizationDto from "../../model/codetable/CtOrganizationDto";
import { AppContext } from "../../Store";
import DisplayOrganizationLogo from "./DisplayOrganizationLogo";

export default function DisplaySponsorList() {
  const { authData } = useContext(AppContext);
  const [sponzorOrganizationList, setSponzorOrganizationList] = useState([]);

  useEffectOnce(() => {
    const requestSponsorOrganizationList = axios.get(Endpoint.SPONSOR_ORGANIZATION_LIST, axiosConfig(authData?.token, {}));

    axios
      .all([requestSponsorOrganizationList])
      .then(
        axios.spread((responseSponsorOrganizationList: any) => {
          setSponzorOrganizationList(responseSponsorOrganizationList.data);
        })
      )
      .catch((e) => {
      });
  });

  return (
    <Panel header={Labels.TITLE_SPONSORS}>
      <div className="p-grid p-justify-around p-fluid">
        {sponzorOrganizationList.map((organization: CtOrganizationDto) => {
          return (
            <div key={organization.id} className="p-xl-1 p-lg-1 p-md-3 p-sm-12 p-col-align-center">
              <DisplayOrganizationLogo key={organization.id} organization={organization} />
            </div>
          );
        })}
      </div>
    </Panel>
  );
}
