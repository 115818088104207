import axios from "axios";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import React, { useContext, useEffect, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useHistory } from "react-router-dom";
import Endpoint from "../../infrastructure/Endpoint";
import Images from "../../infrastructure/Images";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig, CALENDAR_DATE_FORMAT, handleAxiosCallError, imageExtention } from "../../infrastructure/Utils";
import UserReadDto from "../../model/user/UserReadDto";
import { AppContext } from "../../Store";

export default function User() {
  const [userChange, setUserChange] = useState<UserReadDto>();
  const [avatar, setAvatar] = useState<any>({
    cropperOpen: false,
    img: null,
    zoom: 1,
    croppedImg: "",
  });
  const [editor, setEditor] = useState<any>();
  const [avatarChangeVisible, setAvatarChangeVisible] = useState(false);
  const history = useHistory();
  const { authData, showMessage, updateAuthData } = useContext(AppContext);

  useEffect(() => {
    setUserChange(authData?.currentUser);
  }, []);

  const editUser = () => {
    axios
      .put(`${Endpoint.USER}/userList/${userChange?.id}`, userChange, axiosConfig(authData?.token))
      .then((response: any) => {
        updateAuthData(
          JSON.stringify({
            token: authData?.token,
            currentUser: userChange,
          })
        );
        showMessage(MessageType.SUCCESS, Labels.USER_PROFILE_EDIT_SUCCESS, "");
        history.push("/");
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  };

  const _handleFileChange = (e: any) => {
    setAvatar({ ...avatar, img: e.target.files[0] });
  };

  const handleScale = (e: any) => {
    const scale = parseFloat(e.target.value);
    setAvatar({ ...avatar, zoom: scale });
  };

  const handleSave = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      setUserChange({ ...userChange!, profilePicture: croppedImg.substr(croppedImg.indexOf(",") + 1) });
      setAvatarChangeVisible(false);
    }
  };

  const setEditorRef = (editor: any) => {
    setEditor(editor);
  };

  const genders = [
    { name: Labels.USER_PROFILE_GENDER_MALE, code: 0 },
    { name: Labels.USER_PROFILE_GENDER_FEMALE, code: 1 },
  ];
  return (
    <div className="p-col-12 panel-registration">
      <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center">
        <div className="p-grid p-fluid layout-user">
          <div className="p-col-12 p-lg-6">
            <div className="p-col-12 panel-registration">
              <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center div-size">
                <Panel header={Labels.USER_PROFILE_EDIT}>
                  <div className="p-col-12">
                    {!avatarChangeVisible && (
                      <div
                        onClick={() => setAvatarChangeVisible(true)}
                        className="p-col-12 profile-picture"
                        style={{
                          backgroundImage: userChange?.profilePicture
                            ? `url(data:image/${imageExtention(userChange?.profilePicture!)};base64,${userChange?.profilePicture})`
                            : `url(${Images.no_profile})`,
                        }}
                      >
                        <i className="pi pi-pencil custom-pencil"></i>
                      </div>
                    )}

                    {avatarChangeVisible && (
                      <div className="text-center">
                        <AvatarEditor
                          className="margin-bottom-10 avatar-editor-size"
                          ref={setEditorRef}
                          image={avatar.img}
                          width={200}
                          height={200}
                          border={50}
                          color={[255, 255, 255, 0.6]} // RGBA
                          scale={avatar.zoom}
                          rotate={0}
                        />
                        <br />
                        <input hidden id="file" type="file" accept="image/*" onChange={_handleFileChange} />
                        <label className="button-upload-image btn-small" htmlFor="file">
                          {Labels.BUTTON_UPLOAD_IMAGE}
                        </label>
                        <br />
                        <Button className="button-save-picture btn-small" label={Labels.BUTTON_SAVE} onClick={() => handleSave()}></Button>
                        <Button className="button-cancel-picture btn-small" label={Labels.BUTTON_CANCEL} onClick={() => setAvatarChangeVisible(false)}></Button>
                        <input name="scale" type="range" onChange={handleScale} min={avatar.allowZoomOut ? "0.1" : "1"} max="2" step="0.01" defaultValue="1" />
                      </div>
                    )}

                    <div className="p-col-12 login-input">
                      <label className="edit-user-label">{Labels.USER_PROFILE_EMAIL}</label>
                      <InputText
                        disabled={true}
                        className="p-col-12"
                        value={userChange && userChange.email}
                        onChange={(e: any) => {
                          setUserChange({
                            ...userChange!,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="p-col-12 login-input">
                      <label className="edit-user-label">{Labels.USER_PROFILE_CHOSENNAME}</label>
                      <InputText
                        className="p-col-12"
                        value={userChange && userChange.chosenName}
                        onChange={(e: any) => {
                          setUserChange({
                            ...userChange!,
                            chosenName: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="p-col-12 login-input">
                      <label className="edit-user-label">{Labels.USER_PROFILE_DATE_OF_BIRTH}</label>
                      <Calendar
                        autoZIndex
                        value={userChange && userChange.dateOfBirth ? new Date(userChange.dateOfBirth) : undefined}
                        onChange={(e: any) => {
                          setUserChange({
                            ...userChange!,
                            dateOfBirth: e.target.value,
                          });
                        }}
                        showTime={false}
                        hourFormat="24"
                        dateFormat={CALENDAR_DATE_FORMAT}
                        maxDate={new Date()}
                      />
                    </div>
                    <div className="p-col-12 login-input-dropdown">
                      <label className="edit-user-label">{Labels.USER_PROFILE_GENDER}</label>
                      <Dropdown
                        optionLabel="name"
                        optionValue="code"
                        className="dropdown-style"
                        value={userChange && userChange.gender}
                        placeholder={Labels.PLACEHOLDER_GENDER}
                        options={genders}
                        onChange={(e: any) => {
                          setUserChange({
                            ...userChange!,
                            gender: e.value,
                          });
                        }}
                      />
                    </div>
                    <div className="p-col-12 login-input">
                      <label className="edit-user-label">{Labels.USER_PROFILE_HEIGHT}</label>
                      <InputNumber
                        className="p-col-12"
                        value={userChange && userChange.heightCentimeters}
                        onValueChange={(e: any) => {
                          setUserChange({
                            ...userChange!,
                            heightCentimeters: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="p-col-12 login-input">
                      <label className="edit-user-label">{Labels.USER_PROFILE_WEIGHT}</label>
                      <InputNumber
                        className="p-col-12"
                        value={userChange && userChange.weightKilograms}
                        onValueChange={(e: any) => {
                          setUserChange({
                            ...userChange!,
                            weightKilograms: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="p-col-12 login-button">
                      <Button label={Labels.USER_PROFILE_SAVE_CHANGES} className="p-col-12" onClick={() => editUser()} />
                    </div>
                  </div>
                </Panel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
