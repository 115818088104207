import React from "react";
import { Route } from "react-router-dom";
import CrudArticleList from "./components/article/CrudArticleList";
import CrudEventList from "./components/event/CrudEventList";
import CrudFaqList from "./components/faq/CrudFaqList";
import CrudMessageList from "./components/message/CrudMessageList";
import RangList from "./components/rang-list/RangList";
import UserProfile from "./components/user-profile/UserProfile";
import User from "./components/user/User";
import CrudActivityRangeList from "./components/activity-range/CrudActivityRangeList";
import StatisticalData from "./components/statistical-data/StatisticalData";

export default function Content() {
  return (
    <div>
      <Route exact path="/" component={CrudFaqList} />
      <Route exact path="/rankList" component={RangList} />
      <Route exact path="/eventList" component={CrudEventList} />
      <Route exact path="/user" component={User} />
      <Route exact path="/userProfile/:id" component={UserProfile} />
      <Route exact path="/articleList" component={CrudArticleList} />
      <Route exact path="/faqList" component={CrudFaqList} />
      <Route exact path="/messageList" component={CrudMessageList} />
      <Route exact path="/chartList" component={CrudActivityRangeList} />
      <Route exact path="/statisticalData" component={StatisticalData} />
    </div>
  );
}
