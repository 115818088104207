import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from "react";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import RegistrationEventReadDto from "../../model/event/RegistrationEventReadDto";
import { Tooltip } from "primereact/tooltip";

interface RegisteredUsersProp {
  eventOperation: string;
  eventId: number | undefined;
  registrationList: Array<RegistrationEventReadDto>;
  fetchRegistrationList: Function;
  displayRegistrationDialog: boolean;
  setDisplayRegistrationDialog: Function;
  createRegistration: Function;
  chosenName: string;
  setChosenName: Function;
  showSucces: Function;
}
export default function RegisteredUsers(prop: RegisteredUsersProp) {
  const { eventId, chosenName, setChosenName, eventOperation, fetchRegistrationList, registrationList, displayRegistrationDialog, showSucces, setDisplayRegistrationDialog, createRegistration } = prop;

  const [tableRows, setTableRows] = useState(10);
  const [first, setFirst] = useState(0);
  let [messages, setMessages] = useState<any>("");

  useEffect(() => {
    if (eventId) {
      fetchRegistrationList(eventId);
    }
  }, []);

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc: any = new jsPDF.default();
        doc.autoTable(exportColumns, registrationList);
        doc.save(Labels.LABEL_REGISTRATION_EXPORT_PDF);
      });
    });
  };

  const cols = [
    { field: "code", header: Labels.COLUMN_HEADER_REGISTRATION_CODE },
    { field: "chosenName", header: Labels.COLUMN_HEADER_CHOSEN_NAME },
    { field: "email", header: Labels.COLUMN_HEADER_EMAIL },
    { field: "insertTimestamp", header: Labels.COLUMN_HEADER_REGISTRATION_TIME },
  ];

  const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const rightContents = () => (
    <React.Fragment>
      <Button
        type="button"
        tooltip={Labels.TOOLTIP_EXPORT_PDF}
        disabled={registrationList.length === 0 || eventOperation === EntityOperation.DELETE}
        icon="pi pi-file-pdf"
        onClick={exportPdf}
        className="p-button-warning p-mr-2"
      />
    </React.Fragment>
  );

  const leftContents = () => (
    <React.Fragment>
      <Button
        disabled={eventOperation !== EntityOperation.UPDATE}
        className="toolbar-button"
        icon="pi pi-plus"
        onClick={() => {
          setDisplayRegistrationDialog(true);
        }}
      />
    </React.Fragment>
  );

  const registrationCodeBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_REGISTRATION_CODE}</span>
        {rowData.code}
      </React.Fragment>
    );
  };

  const chosenNameBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_REGISTRATION_CODE}</span>
        {rowData.chosenName}
      </React.Fragment>
    );
  };

  const emailBodyTemplate = (rowData: any) => {
    const tooltipTarget = ".p-dt-" + rowData.code;
    const classNameDiv = "p-dt-" + rowData.code;
    return (
      <React.Fragment>
        <Tooltip target={tooltipTarget} content={rowData.email} mouseTrack mouseTrackLeft={10} />
        <span className="p-column-title p-dt-tooltip">{Labels.COLUMN_HEADER_EMAIL}</span>
        <div className={classNameDiv}>{rowData.email}</div>
      </React.Fragment>
    );
  };

  const registrationTimeBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_HEADER_REGISTRATION_TIME}</span>
        {rowData.insertTimestamp}
      </React.Fragment>
    );
  };

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={footerLeftContentsDialog} right={footerRightContentsDialog} />
      </div>
    );
  };

  const footerLeftContentsDialog = () => (
    <React.Fragment>
      <Button
        label={Labels.BUTTON_ADD}
        icon="pi pi-inbox"
        onClick={() => {
          if (!chosenName) {
            showError("", Labels.MESSAGES_CHOSEN_NAME_FIELD);
            return;
          }
          createRegistration(eventId)
            .then((response: any) => {
              showSucces("", `${Labels.MESSAGES_CREATE_REGISTRATION_SUCCESS} ${response.data.data.user.chosenName}. ${Labels.LABEL_REGISTRATION_CODE} ${response.data.data.code}`);
              fetchRegistrationList(eventId);
            })
            .catch((error: any) => {
              showError("", error);
            });
        }}
      />
    </React.Fragment>
  );

  const footerRightContentsDialog = () => (
    <React.Fragment>
      <Button
        label={Labels.BUTTON_CANCEL}
        icon="pi pi-times"
        className="p-button-danger"
        onClick={() => {
          setDisplayRegistrationDialog(false);
          setChosenName("");
        }}
      />
    </React.Fragment>
  );

  const showError = (summary: string, detail: string) => {
    messages.replace({
      severity: "error",
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  return (
    <div>
      <Toolbar right={rightContents} left={leftContents} />
      <div className="datatable-responsive">
        <DataTable
          value={registrationList}
          paginator
          first={first}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={tableRows}
          rowsPerPageOptions={[5, 10, 20]}
          onPage={(e) => {
            setTableRows(e.rows);
            setFirst(e.first);
          }}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          className="p-datatable-responsive"
        >
          <Column className="td-left column-width-250" field={"chosenName"} header={Labels.COLUMN_HEADER_CHOSEN_NAME} body={chosenNameBodyTemplate} />
          <Column className="td-left" field={"email"} header={Labels.COLUMN_HEADER_EMAIL} body={emailBodyTemplate} />
          <Column className="td-left column-width-150" field={"code"} header={Labels.COLUMN_HEADER_REGISTRATION_CODE} body={registrationCodeBodyTemplate} />
          <Column className="td-left column-width-170" field={"insertTimestamp"} header={Labels.COLUMN_HEADER_REGISTRATION_TIME} body={registrationTimeBodyTemplate} />
        </DataTable>
      </div>
      <Dialog
        header={Labels.TITLE_REGISTRATION_CREATE}
        visible={displayRegistrationDialog}
        onHide={() => {
          setDisplayRegistrationDialog(false);
          setChosenName("");
        }}
        style={{ width: "1000px" }}
        footer={dialogFooter()}
      >
        <div className="p-grid p-align-center p-nogutter">
          <div className="p-col-12 padding-0">
            <Messages
              ref={(el: any) => {
                setMessages(el);
              }}
            ></Messages>
          </div>
          <div className="p-col-4">
            <div>{Labels.LABEL_CHOSEN_NAME}</div>
          </div>
          <div className="p-col-8 p-fluid margin-top-bottom-5">
            <InputText
              value={chosenName}
              onChange={(e: any) => {
                setChosenName(e.target.value);
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
