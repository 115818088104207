import axios from "axios";
import moment from "moment";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import Endpoint from "../../infrastructure/Endpoint";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, DATE_FORMAT2, handleAxiosCallError, meterToKilometer } from "../../infrastructure/Utils";
import RangDto from "../../model/rang-list/RangDto";
import { AppContext } from "../../Store";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function RangList() {
  const { authData, showMessage, setShowBlockUI } = useContext(AppContext);
  const [rangListMonth, setRangListMonth] = useState<Array<RangDto>>();
  const [rangListYear, setRangListYear] = useState<Array<RangDto>>();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffectOnce(() => {
    setShowBlockUI(true);
    const date = new Date();
    const dateFromMonth = moment(new Date(date.getFullYear(), date.getMonth(), 1)).utc(true).format(DATE_FORMAT2);
    const dateToMonth = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0))
      .utc(true)
      .format(DATE_FORMAT2);
    const dateFromYear = moment(new Date(date.getFullYear(), 0, 1)).utc(true).format(DATE_FORMAT2);
    const dateToYear = moment(new Date(date.getFullYear(), 11, 31)).utc(true).format(DATE_FORMAT2);

    const requestRangListMonth = axios.get(Endpoint.RANG_LIST, axiosConfig(authData.token, { dateFrom: dateFromMonth, dateTo: dateToMonth }));
    const requestRangListYear = axios.get(Endpoint.RANG_LIST, axiosConfig(authData.token, { dateFrom: dateFromYear, dateTo: dateToYear }));

    axios
      .all([requestRangListMonth, requestRangListYear])
      .then(
        axios.spread((responseRangMonth: any, responseRangYear: any) => {
          responseRangMonth.data.data.forEach((element: any, index: number) => {
            responseRangMonth.data.data[index] = { ...element, rank: index + 1 + "." };
          });
          responseRangYear.data.data.forEach((element: any, index: number) => {
            responseRangYear.data.data[index] = { ...element, rank: index + 1 + "." };
          });
          setRangListMonth(responseRangMonth.data.data);
          setRangListYear(responseRangYear.data.data);
          setShowBlockUI(false);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
        setShowBlockUI(false);
      });
  });

  const rankBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_TITLE_RANK}</span>
        {rowData.rank}
      </React.Fragment>
    );
  };

  const distanceBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_TITLE_DISTANCE}</span>
        {meterToKilometer(rowData.totalDistanceMeters, 2) + " " + Labels.LABEL_KM}
      </React.Fragment>
    );
  };

  const chosenNameBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{Labels.COLUMN_TITLE_CHOSEN_NAME}</span>
        {rowData.chosenName}
      </React.Fragment>
    );
  };

  return (
    <div className="layout-rang-list p-grid p-justify-center">
      <div className="p-lg-8 p-md-8 p-sm-12 p-col-12 ">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header={Labels.LABEL_RANG_LIST_MONTH}>
            <div className="datatable-responsive">
              <DataTable
                value={rangListMonth}
                paginator={true}
                alwaysShowPaginator={false}
                rows={20}
                rowsPerPageOptions={[5, 10, 20]}
                selectionMode="single"
                onRowSelect={(e) => {
                  const win: Window | null = window.open(`/userProfile/${e.data.id}`, "_blank");
                }}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                className="p-datatable-responsive"
              >
                <Column className="column-left column-width-120" field={"rank"} header={Labels.COLUMN_TITLE_RANK} sortable body={rankBodyTemplate} />
                <Column className="column-left" field={"chosenName"} header={Labels.COLUMN_TITLE_CHOSEN_NAME} filter filterMatchMode="contains" body={chosenNameBodyTemplate} />
                <Column className="column-right column-width-120" field={"totalDistanceMeters"} header={Labels.COLUMN_TITLE_DISTANCE} sortable body={distanceBodyTemplate} />
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel header={Labels.LABEL_RANG_LIST_YEAR}>
            <div className="datatable-responsive">
              <DataTable
                value={rangListYear}
                paginator={true}
                alwaysShowPaginator={false}
                rows={20}
                rowsPerPageOptions={[5, 10, 20]}
                selectionMode="single"
                onRowSelect={(e) => {
                  const win: Window | null = window.open(`/userProfile/${e.data.id}`, "_blank");
                }}
                emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
                className="p-datatable-responsive"
              >
                <Column className="column-left column-width-120" field={"rank"} header={Labels.COLUMN_TITLE_RANK} sortable body={rankBodyTemplate} />
                <Column className="column-left" field={"chosenName"} header={Labels.COLUMN_TITLE_CHOSEN_NAME} filter filterMatchMode="contains" sortable body={chosenNameBodyTemplate} />
                <Column className="column-right column-width-120" field={"totalDistanceMeters"} header={Labels.COLUMN_TITLE_DISTANCE} sortable body={distanceBodyTemplate} />
              </DataTable>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
}
